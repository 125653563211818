import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map, of} from 'rxjs';
import {REMIT_BASE_URL} from './config';


export interface RemitStaffInfo{
  uid: string
  email: string
  name: string
}


@Injectable({
  providedIn: 'root',
})
export class RemitStaffService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  private cache: Map<string, RemitStaffInfo> = new Map();

  getInfo(uid: string): Observable<RemitStaffInfo> {
    const cachedData = this.cache.get(uid);
    if (cachedData) {
      return of(cachedData);
    }

    return this.http.get<{ body: RemitStaffInfo }>(
        this.remitApiUrl + `/staff/info/${uid}`,
    ).pipe(
        map((v) => {
          const data = v.body;
          this.cache.set(uid, data);
          return data;
        }),
    );
  }
}
