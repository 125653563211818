import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {KycArcType, RemitApiResponse, UserVerificationService} from './kyc/user-verification.service';
import {RemitMember, RemitMemberUpdatableInfo} from './remit-member.service';


export interface MigratedRegistrationQueryParams {
  pageSize?: number
  pageNumber?: number
  arcNumber?: string | null;
  phoneNumber?: string| null;
  primaryPhoneNumber?: string| null;
  name?: string| null;
  sortDirection?: number| null;
  sortBy?: string| null;
}

export interface MigratedRegistrationRecord {
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  member: Partial<RemitMember>
  isMigrated: boolean
}

export interface MigratedRegistrationRecords{
  body: MigratedRegistrationRecord[]
  totalData: number
}

@Injectable({
  providedIn: 'root',
})
export class MigratedRegistrationService {
  constructor(
    private http: HttpClient,
    private userVerificationService: UserVerificationService,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }


  queryMigratedRegistrations(params?: Partial<MigratedRegistrationQueryParams>,
  ): Observable<MigratedRegistrationRecords> {
    return this.http.post<MigratedRegistrationRecords>(
        this.remitApiUrl + '/staff/migrated-registrations',
        params,
    );
  }

  getMigratedRegistration(id: string): Observable<MigratedRegistrationRecord> {
    return this.http.get<{body: MigratedRegistrationRecord}>(
        this.remitApiUrl + '/staff/migrated-registration/' + id,
    ).pipe(map((v)=> v.body));
  }

  updateMigratedRegistration(
      id: string, data: Partial<RemitMemberUpdatableInfo>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/migrated-registration/' + id,
        data,
    );
  }

  createMigratedRegistration(
      data: Partial<RemitMemberUpdatableInfo>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/migrated-registration',
        data,
    );
  }

  uploadImage(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<RemitApiResponse & {url:string}>(
        `${this.remitApiUrl}/staff/upload-image`,
        formData,
    ).pipe(map((v)=>v.url));
    // return this.userVerificationService.uploadImage(file);
  }

  getARCTypes(): Observable<KycArcType[]> {
    return this.userVerificationService.getARCTypes();
  }
}
