import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {RemitPublicImageUploaderService} from './remit-public-image-uploader.service';


export interface BannerQueryParams {
  pageSize?: number
  pageNumber?: number
  isActive?: 'Y'| 'N' | '' |null;
}

export interface RemitBanners{
  body: RemitBanner[]
  totalData: number
}

export type RemitBanner =
  RemitBannerInfo & RemitBannerEditable

export interface RemitBannerInfo{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  version: string
}

export interface RemitBannerEditable{
  imageUrl: string
  url: string
  isActive: 'Y'| 'N' | '';
}

@Injectable({
  providedIn: 'root',
})
export class RemitContentBannerService {
  constructor(
    private http: HttpClient,
    private publicImageUploader: RemitPublicImageUploaderService,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  queryBanners(params: BannerQueryParams,
  ): Observable<RemitBanners> {
    return this.http.post<RemitBanners>(
        this.remitApiUrl + '/staff/contents/banners',
        params,
    );
  }
  createBanner(params: Partial<RemitBannerEditable>,
  ): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/contents/banners/create',
        params,
    );
  }
  updateBanner(id: string, params: Partial<RemitBannerEditable>,
  ): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/contents/banners/${id}`,
        params,
    );
  }
  getBanner(id: string): Observable<RemitBanner> {
    return this.http.get<{body: RemitBanner}>(
        this.remitApiUrl + `/staff/contents/banners/${id}`,
    ).pipe(map((v)=> v.body));
  }

  uploadPublicImage(file: File): Observable<string> {
    return this.publicImageUploader.uploadPublicImage(file);
  }
}
