import {Injectable} from '@angular/core';
import {AuthService} from '@indosuara/auth';
import {Observable, map} from 'rxjs';

export interface RemitRoute {
  icon?: string;
  name: string;
  path?: string;
  requiredPermission?: string | string[];
  childrens?: RemitRoute[];
}

function filterRoutesByPermissions(
    routes: RemitRoute[],
    permissions: string[],
): RemitRoute[] {
  return routes
      .filter((route) => {
      // If there's no requiredPermission, include the route
        if (!route.requiredPermission) return true;
        // Check if requiredPermission is an array or a string
        if (Array.isArray(route.requiredPermission)) {
        // If it's an array, check if at least one permission is included
          return route.requiredPermission.some((perm) =>
            permissions.includes(perm),
          );
        } else {
        // If it's a string, check if the permission is included
          return permissions.includes(route.requiredPermission);
        }
      })
      .map((route) => ({
        ...route,
        childrens: route.childrens ?
        filterRoutesByPermissions(route.childrens, permissions) :
        undefined,
      }));
}

const routes: RemitRoute[] = [
  {
    icon: 'mms',
    name: 'Content',
    requiredPermission: 'remitz:staff',
    childrens: [
      {
        icon: 'perm_media',
        name: 'Banner',
        path: 'banners',
        requiredPermission: 'remitz:staff',
      },
    ],
  },
  {
    icon: 'settings',
    name: 'Configuration',
    requiredPermission: [
      'remitz:superadmin',
      'remitz:beneficiaryconfig',
      'remitz:promo',
    ],
    childrens: [
      {
        icon: 'currency_exchange',
        name: 'Kurs',
        path: 'rate',
        requiredPermission: 'remitz:superadmin',
      },
      {
        icon: 'manage_accounts',
        name: 'Beneficiary Limit',
        path: 'beneficiary-limit',
        requiredPermission: 'remitz:beneficiaryconfig',
      },
      {
        icon: 'local_atm',
        name: 'Fees',
        path: 'fees',
        requiredPermission: 'remitz:superadmin',
      },
      {
        icon: 'money_off',
        name: 'Promo Campaign',
        path: 'promo-campaigns',
        requiredPermission: 'remitz:promo',
      },
      {
        icon: 'account_balance',
        name: 'Kode Bank',
        path: 'banks',
        requiredPermission: 'remitz:bankcode',
      },
      {
        icon: 'account_balance_wallet',
        name: 'Loan Disbursement',
        path: 'loan-disbursement',
        requiredPermission: 'remitz:superadmin',
      },
    ],
  },
  {
    icon: 'groups',
    name: 'Members',
    requiredPermission: 'remitz:cs',
    childrens: [
      {
        icon: 'person_add',
        name: 'Registration (KYC)',
        path: 'accept-member',
      },
      {
        icon: 'account_box',
        name: 'View Members',
        path: 'members',
      },

      {
        icon: 'person_pin_circle',
        name: 'Migrasi RK',
        path: 'migrations',
      },
    ],
  },
  {
    icon: 'receipt_long',
    name: 'Transactions',
    requiredPermission: 'remitz:cs',
    childrens: [
      {
        icon: 'pageview',
        name: 'View Remit Invoice',
        path: 'invoices',
      },
      {
        icon: 'fact_check',
        name: 'Approve Shop Transactions',
        path: 'approve-transactions',
        requiredPermission: 'remitz:approve',
      },
      {
        icon: 'receipt',
        name: '發票',
        path: 'fapiao',
        requiredPermission: 'remitz:einvoice',
      },
    ],
  },
  {
    name: 'Shop',
    requiredPermission: 'remitz:cs',
    childrens: [
      {
        icon: 'category',
        name: 'Items',
        path: 'shop-items',
        requiredPermission: 'remitz:staff',
      },
      {
        icon: 'list_alt',
        name: 'Orders',
        path: 'shop-orders',
        requiredPermission: 'remitz:staff',
      },
      {
        icon: 'list_alt',
        name: 'Home Page Listing',
        path: 'shop-listing',
        requiredPermission: 'remitz:staff',
      },
      {
        icon: 'tune',
        name: 'Shop Feature',
        path: 'shop-feature',
        requiredPermission: 'remitz:superadmin',
      },
    ],
  },
  {
    name: '🇮🇩 Bank Indo',
    requiredPermission: 'remitz:transfer',
    childrens: [
      {
        icon: 'sync_lock',
        name: 'Process',
        path: 'fi',
      },
      {
        icon: 'feedback',
        name: 'Failed',
        path: 'failed',
      },
      {
        icon: 'playlist_add_check_circle',
        name: 'Vostro Record',
        path: 'vostro-record',
        requiredPermission: 'remitz:vostrorecord',
      },
    ],
  },
  {
    name: '🇹🇼 Bank TWN',
    requiredPermission: 'remitz:bankreport',
    childrens: [
      {
        icon: 'assured_workload',
        name: 'Anti Money Laundering',
        path: 'bankreport',
      },
    ],
  },

  {
    icon: 'trending_up',
    name: 'Summary',
    requiredPermission: [
      'remitz:superadmin',
      'remitz:transfer',
      'remitz:bankreport',
    ],
    childrens: [
      {
        icon: 'app_registration',
        name: 'Registration',
        path: 'member-registration-summary',
      },
      {
        icon: 'contact_page',
        name: 'Member Transactions',
        path: 'member-trx-summary',
      },
      {
        icon: 'login',
        name: 'Inflow Summary',
        path: 'remit-inflow-summary',
      },
      {
        icon: 'logout',
        name: 'Outflow Summary',
        path: 'remit-outflow-summary',
      },
    ],
  },

  {
    name: 'System Logs',
    requiredPermission: 'remitz:superadmin',
    childrens: [
      {
        name: 'ECpay Logger',
        path: 'third-party-logs',
      },
    ],
  },
];

@Injectable({
  providedIn: 'root',
})
export class RemitNavigationService {
  constructor(private authService: AuthService) {}

  getRoutes(): Observable<RemitRoute[]> {
    return this.authService.authState().pipe(
        map((user) => {
          const filtered = filterRoutesByPermissions(
              routes,
              user?.permissions ?? [],
          );

          return filtered;
        }),
    );
  }
}
