<div mat-dialog-title class="title"> {{data.action}} {{data.transactionId}}</div>
<div mat-dialog-content class="content" [formGroup]="actionForm">
  <div *ngIf="transaction$ | async as transaction; else loading" style="display: flex; flex-direction: column; gap: 1rem;">
    <div>
      transaction for beneficiary <strong>{{transaction.beneficiaryName}} </strong> {{transaction.bankName}} {{transaction.beneficiaryBankCode}} -  {{transaction.beneficiaryAccountNumber}}
    </div>
    <div class="warning-box">
      Fill <strong>{{transaction.sender?.name}}</strong> to confirm action.
    </div>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Customer Name</mat-label>
    <input 
    matInput 
    formControlName="nameConfirmation" 
    indosuaraRemitInvoiceActionUppercase
    [placeholder]="customerName ?? ''"
    name="customername"
    >
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Comment</mat-label>
    <textarea matInput formControlName="comment" name="transactioncomments" ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button (click)="submit()" color="primary" [disabled]="!isValidToSubmit">Confirm</button>
</div>


<ng-template #loading>
  <div style="display: flex; gap: 1rem; align-items: center;">
    <span class="loader"></span> Loading...
  </div>
</ng-template>