import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {REMIT_BASE_URL} from './config';


export interface RemitEcpayLogsPagination {
  pageSize?: number
  pageNumber?: number
}

export interface EcpayLogEntries{
  body: EcpayLogEntry[]
  totalData: number
}

export interface EcpayLogEntry{
  id: string
  log: string
  createdAt: string
}

@Injectable({
  providedIn: 'root',
})
export class RemitzEcpayLogsService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  queryLogs(params: RemitEcpayLogsPagination) {
    return this.http.post<EcpayLogEntries>(
        this.remitApiUrl + '/staff/ecpay-logs',
        params,
    );
  }
}
