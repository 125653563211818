import {Component} from '@angular/core';

@Component({
  selector: 'indosuara-page-not-found',
  template: `
    <p>
      This page doesn't exist. Go back to
      <a routerLink="/home">home</a>.
    </p>
  `,
  styles: [],
})
/**
 * Default 404 page
 */
export class PageNotFoundComponent {

}
