import * as saveAs from 'file-saver';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

import {Injectable} from '@angular/core';
import {MsjhFontTtfResolver} from '@indosuara/msjh-font';
import jsPDF from 'jspdf';
import {ShopOrder} from './remit-shop.service';

export interface OrderShopExportFormat {
  貨號?: string;
  品名?: string;
  重量?: string;
  價格?: number;
  數量?: number;
  小計?: number;
  最低賣價?:number;
  備註?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderShopExportExcelService {
  private base64Cache: { [url: string]: string } = {};

  constructor(
    private fontResolver: MsjhFontTtfResolver,
  ) {
  }

  private async convertToBase64(url: string): Promise<string> {
    if (this.base64Cache[url]) {
      return this.base64Cache[url];
    }

    const res = await fetch(url);
    const buffer = await res.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0, len = bytes.byteLength; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = window.btoa(binary);

    this.base64Cache[url] = base64;

    return base64;
  }

  private _generateFileName(): string {
    return `order-${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  private _generatePdfFileName(): string {
    return `order-${moment().format('YYYY-MM-DD_HH-mm-ss')}.pdf`;
  }

  async exportToPdf(orderData: ShopOrder, staffName: string) {
    const doc = new jsPDF('l', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const font = await this.fontResolver.resolve();
    const logo = await this.convertToBase64('assets/logo_indosuara_hd.png');

    doc.addFileToVFS('msjh-normal.ttf', font);
    doc.addFont('msjh-normal.ttf', 'msjh', 'normal');

    // Title with logo
    let currentY = 10;
    const logoWidth = 40;
    const logoHeight = 10;
    doc.addImage(logo, 'PNG', pageWidth/2 - logoWidth/2, currentY, logoWidth, logoHeight);
    currentY += logoHeight + 5;

    // Header
    doc.setFontSize(10).setFont('msjh', 'normal');

    // Add title and order ID
    doc.setFontSize(16).setFont('msjh', 'normal');
    doc.text(
        '出貨單',
        pageWidth / 2 - 10,
        currentY + 6,
    );

    // Add order ID on the right side
    doc.setFontSize(10);
    doc.text(
        orderData.id,
        pageWidth - 60,
        currentY + 6,
    );

    currentY += 15;
    doc.setFontSize(10);

    // Create header table
    const headerTableWidth = pageWidth - 20;
    const headerTableX = 10;
    const headerCellHeight = 5;
    const leftColumnWidth = headerTableWidth / 2;

    // Left column headers
    doc.text('客戶名稱:', headerTableX + 2, currentY + 2);
    doc.text('客戶編號:', headerTableX + 2, currentY + headerCellHeight + 2);
    doc.text('送貨地址:', headerTableX + 2, currentY + headerCellHeight * 2 + 2);
    doc.text('電話:', headerTableX + 2, currentY + headerCellHeight * 3 + 2);

    // Right column headers
    doc.text('送貨日期:', headerTableX + leftColumnWidth + 2, currentY + 2);
    doc.text('業務人員:', headerTableX +
      leftColumnWidth + 2, currentY + headerCellHeight + 2);

    // Left column values
    doc.text(orderData.name || '', headerTableX + 30, currentY + 2);
    doc.text(orderData.agentNumber || '',
        headerTableX + 30, currentY + headerCellHeight + 2);
    doc.text(orderData.address || '',
        headerTableX + 30, currentY + headerCellHeight * 2 + 2);
    doc.text(orderData.phoneNumber || '',
        headerTableX + 30, currentY + headerCellHeight * 3 + 2);

    // Right column values
    doc.text(
        orderData.canShipAt ? moment(orderData.canShipAt).format('dddd, MMMM DD, YYYY') :
        moment().format('dddd, MMMM DD, YYYY'),
        headerTableX + leftColumnWidth + 30,
        currentY + 2,
    );
    doc.text(
        staffName || '',
        headerTableX + leftColumnWidth + 30,
        currentY + headerCellHeight + 2,
    );

    currentY += headerCellHeight * 4 + 2;

    // Table
    const columnWidths = [35, 140, 20, 15, 15, 15, 18, 15];
    const tableHeaders = ['貨號', '品名', '重量', '價格', '數量', '小計', '最低賣價', '備註'];
    const rowHeight = 10;
    const marginBottom = 20; // Space to leave at bottom of page

    // Function to draw table headers
    const drawTableHeaders = (yPosition: number) => {
      let x = 10;
      doc.setFont('msjh', 'normal');
      tableHeaders.forEach((header, i) => {
        doc.rect(x, yPosition, columnWidths[i], rowHeight);
        if (i==1) {
          doc.text(header, x + columnWidths[i]/2, yPosition + 7);
        } else if (i==0) {
          doc.text(header, x + 10, yPosition + 7);
        } else {
          doc.text(header, x + 2, yPosition + 7);
        }
        x += columnWidths[i];
      });
      return yPosition + rowHeight;
    };

    // Draw initial table headers
    currentY = drawTableHeaders(currentY);

    // Draw table rows with pagination
    doc.setFont('msjh', 'normal');
    for (let i = 0; i < orderData.itemsView.length; i++) {
      const item = orderData.itemsView[i];

      // Check if we need a new page
      if (currentY + rowHeight > pageHeight - marginBottom) {
        // Add a new page
        doc.addPage();
        currentY = 10; // Reset Y position for the new page

        // Redraw table headers on the new page
        currentY = drawTableHeaders(currentY);
      }

      let x = 10;
      const row = [
        item.sku || '',
        item.name || '',
        item.size || '',
        item.orderPrice?.toString() || '',
        item.quantity?.toString() || '',
        (item.quantity * item.orderPrice).toString() || '',
        item.price?.toString() || '',
        '',
      ];

      row.forEach((cell, i) => {
        const cellWidth = columnWidths[i] - 2;
        let wrappedText;

        // For column index 2 (重量/size), trim the next overflowed text
        if (i === 2) {
          wrappedText = truncateToFix(cellWidth, cell, doc);
        } else {
          wrappedText = doc.splitTextToSize(cell, cellWidth);
        }

        doc.rect(x, currentY, columnWidths[i], rowHeight);
        doc.text(wrappedText, x + 2, currentY + 7);
        x += columnWidths[i];
      });

      currentY += rowHeight;
    }

    // Check if total row will fit on current page
    if (currentY + rowHeight > pageHeight - marginBottom) {
      // Add a new page
      doc.addPage();
      currentY = 10; // Reset Y position for the new page
    }

    // Total row
    const totalQuantity = orderData.itemsView.reduce((acc, cur) => acc + cur.quantity, 0);
    const totalOrderValue = orderData.totalPrice;

    let x = 10;
    // Note
    doc.rect(x, currentY, columnWidths[0]+columnWidths[1], rowHeight);
    x += columnWidths[0]+columnWidths[1];

    doc.setFontSize(8);
    const noteText =
          'Setiap Produk SariAyu dapat di TUKAR (ganti barang) ' +
          'paling LAMBAT 6 Bulan setelah tanggal pembelian ' +
          '每個 SARIAYU 商品於購買日期6個月內可更換同類或其類商';
    const wrappedNote = doc.splitTextToSize(noteText,
        columnWidths[0]+columnWidths[1]);
    doc.text(wrappedNote, 10+2, currentY+5);

    doc.setFontSize(10);
    doc.rect(x, currentY, columnWidths[2] +
      columnWidths[3], rowHeight);
    doc.text('總計NT$', x + 2, currentY + 7);
    x += columnWidths[2] +
    columnWidths[3];

    doc.rect(x, currentY, columnWidths[4], rowHeight);
    doc.text(totalQuantity.toString(), x + 2, currentY + 7);
    x += columnWidths[4];

    doc.rect(x, currentY, columnWidths[5], rowHeight);
    doc.text(totalOrderValue.toString(), x + 2, currentY + 7);

    x+=columnWidths[5];

    doc.rect(x, currentY, columnWidths[6], rowHeight);

    x+=columnWidths[6];

    doc.rect(x, currentY, columnWidths[7], rowHeight);

    // Check if note will fit on current page
    currentY += rowHeight + 10;
    if (currentY + 10 > pageHeight - marginBottom) {
      // Add a new page
      doc.addPage();
      currentY = 10; // Reset Y position for the new page
    }

    // Save PDF
    doc.save(this._generatePdfFileName());
  }

  exportToExcel(orderData: ShopOrder, staffName: string) {
    const headerAoA = [
      ['', '', 'INDOSUARA', ''],
      ['', '', '', orderData.id ?? ''],
      ['', '', '出貨單'],
      ['客戶名稱', orderData.name ?? ''],
      ['客戶編號', orderData.agentNumber ?? '', '送貨日期',
        orderData.canShipAt ?? moment().format('YYYY-MM-DD')],
      ['送貨地址', orderData.address ?? ''],
      ['電話', orderData.phoneNumber ?? '', '業務人員', staffName],
    ];

    const ws = XLSX.utils.aoa_to_sheet(headerAoA);

    const data: OrderShopExportFormat[] = orderData.itemsView.map((itemView: any) => ({
      貨號: itemView.sku,
      品名: itemView.name,
      重量: itemView.size,
      價格: itemView.orderPrice,
      數量: itemView.quantity,
      小計: itemView.orderPrice * itemView.quantity,
      最低賣價: itemView.price,
      備註: '',
    }));


    const totalQuantity = orderData.itemsView.reduce((acc, cur) => acc + cur.quantity, 0);
    const totalOrderValue = orderData.totalPrice;
    data.push({
      貨號: `Setiap Produk SariAyu dapat di TUKAR (ganti barang)
      paling LAMBAT 6 Bulan setelah tanggal pembelian 每個 SARIAYU 商品於購買日期6個月內可更換同類或其類商`,
      品名: '總計NT$',
      數量: totalQuantity,
      小計: totalOrderValue,
    });

    XLSX.utils.sheet_add_json(ws, data, {
      origin: {r: headerAoA.length, c: 0},
      skipHeader: false,
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Order');

    const excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const dataBlob: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(dataBlob, this._generateFileName());
  }
}


function truncateToFix(cellWidth: number, cell: string, doc: jsPDF): string[] {
  let wrappedText;
  const maxWidth = cellWidth;
  const text = cell.trim();
  if (doc.getTextWidth(text) > maxWidth) {
    const words = text.split(' ');
    let result = '';
    for (const word of words) {
      if (doc.getTextWidth(result + ' ' + word) <= maxWidth) {
        result += (result ? ' ' : '') + word;
      } else {
        break;
      }
    }
    wrappedText = [result ||
      words[0].substring(0, Math.
          floor(maxWidth / doc.getCharWidthsArray(words[0])[0]))];
  } else {
    wrappedText = [text];
  }
  return wrappedText;
}

