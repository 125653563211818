import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';

export interface RemitScreeningLimits{
  body: RemitScreeningLimit[]
  totalData: number
}

export type RemitScreeningLimit = RemitScreeningLimitBasic
& RemitScreeningLimitUpdatable

export interface RemitScreeningLimitBasic{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  isDeleted: 'Y' | 'N' | ''
}

export interface RemitScreeningLimitUpdatable {
  userId?: string | null,
  bankId?: string | null,
  limitAt?: string | null,
  limitType?: string | null,
  note?:string|null,
}


export interface RemitScreeningQueryParams{
  pageNumber?: number,
  pageSize?: number,
  sortBy?: string,
  sortDirection?: -1 | 0 | 1
  isDeleted?: 'Y' | 'N' | ''
  limitType?: 'limitPerMonth' | 'limitPerYear' | 'limitForever'
  bankId?: string
  userId?: string | null
}

@Injectable({
  providedIn: 'root',
})
export class RemitScreeningLimitService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }


  createScreeningLimit(params: RemitScreeningLimitUpdatable) {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/bank-screen-limit',
        params,
    );
  }


  getScreeningLimit(id: string): Observable<RemitScreeningLimit> {
    return this.http.get<{
      body: RemitScreeningLimit
    }>(
        this.remitApiUrl + '/staff/bank-screen-limit/' + id,
    ).pipe(
        map((value)=> value.body),
    );
  }


  deleteScreeningLimit(id: string) {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/bank-screen-limit/${id}/delete`,
        null,
    );
  }

  queryScreeningLimit(params: RemitScreeningQueryParams) {
    return this.http.post<RemitScreeningLimits>(
        this.remitApiUrl + '/staff/bank-screen-limits',
        params,
    );
  }
}
