import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {RemitShopItem, RemitShopService} from '@indosuara/remit-api';
import {Observable} from 'rxjs';

@Component({
  selector: 'indosuara-shop-item-preview [id]',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './shop-item-preview.component.html',
  styleUrls: ['./shop-item-preview.component.css'],
})
export class ShopItemPreviewComponent implements OnInit {
  @Input() id!: string;
  constructor(private service: RemitShopService) {
  }
  ngOnInit(): void {
    this.item$ = this.service.getShopItem(this.id ?? '');
  }


  item$?: Observable<RemitShopItem>;
}
