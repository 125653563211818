import {CommonModule} from '@angular/common';
import {Component, inject, Input, OnInit} from '@angular/core';
import {RemitShopInventoryService, StockInfo} from '@indosuara/remit-api';
import {catchError, Observable, of, switchMap} from 'rxjs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@Component({
  selector: 'indosuara-check-stock-text',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './check-stock-text.component.html',
  styleUrl: './check-stock-text.component.css',
})
export class CheckStockTextComponent implements OnInit {
  private inventoryService = inject(RemitShopInventoryService);

  stock$!: Observable<StockInfo & {errorMessage?: string}>;
  ngOnInit(): void {
    this.stock$ = this.inventoryService.getLocationId(this.shopId).pipe(
        switchMap((locationId)=>{
          return this.inventoryService.getInventoryQuantity(
              locationId, this.itemSKU);
        }),
        catchError((err)=>{
          return of({
            quantity: 0,
            productId: '',
            locationId: '',
            sku: this.itemSKU,
            id: '',
            shopItemId: '',
            errorMessage: err.message ?? err?.toString() ??
            'Failed to get stock',
          });
        }),
    );
  }

  @Input() itemSKU!: string;
  @Input() shopId!: string;
}
