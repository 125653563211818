import {CommonModule} from '@angular/common';
import {Component, Directive, HostListener, Inject} from '@angular/core';
import {FormControl, FormGroup, NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {Observable, tap} from 'rxjs';
import {RemitInvoice, RemitInvoiceService} from '../remit-invoice.service';


export interface RemitRequiredCommentDialogData {
  transactionId: string;
  action: string;
}
@Directive({
  selector: 'input [indosuaraRemitInvoiceActionUppercase]',
  standalone: true,
})
export class RemitInvoiceActionUppercaseDirective {
  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    this.ngControl.control?.
        setValue(event.target.value.toUpperCase());
  }
}


@Component({
  selector: 'indosuara-remit-required-comment-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    RemitInvoiceActionUppercaseDirective,
  ],
  templateUrl: './remit-required-comment-dialog.component.html',
  styleUrls: ['./remit-required-comment-dialog.component.css'],
})
export class RemitRequiredCommentDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RemitRequiredCommentDialogComponent>,
    private remitInvoiceService: RemitInvoiceService,
    @Inject(MAT_DIALOG_DATA) public data: RemitRequiredCommentDialogData,
  ) {
    this.transaction$ = this.remitInvoiceService.
        getOrder(data.transactionId).pipe(
            tap((res)=>{
              this.customerName = res.sender?.name;
            }),
        );
  }

  transaction$: Observable<RemitInvoice>;
  customerName?: string;


  actionForm = new FormGroup({
    nameConfirmation: new FormControl('', {validators: [Validators.required]}),
    comment: new FormControl('', {validators: [Validators.required]}),
  });

  get isCustomerNameValid() {
    if (!this.customerName) return false;
    return this.actionForm.value.nameConfirmation === this.customerName;
  }

  get isValidToSubmit() {
    if (!this.isCustomerNameValid) return false;
    return this.actionForm.valid;
  }

  submit() {
    if (!this.isValidToSubmit) return;
    this.dialogRef.
        close(`${this.data.action} : ${this.actionForm.value.comment}`);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


