import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REMIT_BASE_URL} from './config';

@Injectable({
  providedIn: 'root',
})
export class RemitVoucherService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,

  ) { }

  queryRemitVouchers(params: RemitVoucherQueryParams):
  Observable<RemitVouchers> {
    return this.http.post<RemitVouchers>(
        this.remitApiUrl+'/staff/vouchers',
        params);
  }
}

export interface RemitVouchers {
  totalData: number
  body: RemitVoucher[]
}

export interface RemitVoucher {
  id: string;
  userId: string;
  itemId: string;
  orderId: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  usedAt?: string ;
  expiredAt: string;
}

export interface RemitVoucherQueryParams {
  pageNumber?: number
  pageSize?:number
  sortBy?:string
  sortDirection ?: -1|0|1
  isUsed?: 'Y'|'N'|''
  ids?: string[]
  hasExpired?:'Y'|'N'|''
  userId?:string | null
}
