import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, delay, of, take, tap} from 'rxjs';

export interface User {
  uid: string
  email: string
  name: string
  emailVerified: boolean
  manager: boolean
  staff: boolean
  idpAgentCode: string
  permissions: string[]
}

export interface ChangePasswordRequest{
  oldPassword: string
  newPassword: string
  newPasswordConfirm: string
}

@Injectable()
export abstract class AuthService {
  abstract login(email: string, password: string): Observable<string>
  abstract loginWithGoogle(): Observable<string>
  abstract logout(): void
  abstract getToken(): Promise<string | undefined>
  abstract authState(): Observable<User | null>
  abstract changePassword(req: ChangePasswordRequest): Observable<any>
}


@Injectable()
export class DummyAuthService implements AuthService {
  private user = new BehaviorSubject<User | null>(null);

  constructor() {
    this.checkCacheLogin();
  }


  login(email: string, password: string): Observable<string> {
    return of('logged in').pipe(
        tap(()=> {
          this.cacheLogin(email, password);
        }),
        tap(()=>{
          this.user.next({
            uid: 'fakeuid',
            email: email,
            name: email.split('@')[0] ?? '',
            emailVerified: false,
            manager: false,
            staff: false,
            idpAgentCode: '',
            permissions: [],
          });
        }),
    );
  }
  loginWithGoogle(): Observable<string> {
    throw new Error('Method not implemented.');
  }
  logout(): void {
    this.clearCacheLogin();
    this.user.next(null);
  }
  async getToken(): Promise<string> {
    return '';
  }
  authState(): Observable<User | null> {
    return this.user.asObservable().pipe(delay(100));
  }

  changePassword(req: ChangePasswordRequest): Observable<any> {
    throw new Error('Method not implemented.');
  }

  private checkCacheLogin() {
    const email = localStorage.getItem('dummy-email');
    const password = localStorage.getItem('dummy-password');

    if (!email) return;
    if (!password) return;

    this.login(email, password).pipe(take(1)).subscribe();
  }

  private cacheLogin(email: string, password: string) {
    localStorage.setItem('dummy-email', email);
    localStorage.setItem('dummy-password', password);
  }

  private clearCacheLogin() {
    localStorage.clear();
  }
}
