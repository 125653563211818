import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';


export interface VostroRecords{
  body: VostroRecord[]
  totalData: number
}

export interface VostroRecord{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  bank: string
  currency: string
  topUpAmount: string
  balanceWhenCreatedAt: string
  verifiedAt: string
  verifiedBy: string
  balanceWhenVerifiedAt: string
  version: string
}


export interface VostroRecordQueryParams{
  pageNumber?: number
  pageSize?: number
  bank?: string | null
  currency?: string | null
  createdAtStart?: string | null
  createdAtEnd?: string | null
  verifiedAtStart?: string | null
  verifiedAtEnd?: string | null
}

export interface VostroCreateFields{
  currency?: string
  topUpAmount: string
  bank: string
}

@Injectable({
  providedIn: 'root',
})
export class RemitVostroRecordService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  // record create done
  // record get
  // record query
  // record undo
  // record update
  // record verify

  createVostroRecord(params: VostroCreateFields) {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/vostro-record',
        params,
    );
  }


  getVostroRecord(id: string): Observable<VostroRecord> {
    return this.http.get<{body: VostroRecord}>(
        this.remitApiUrl + `/staff/vostro-record/${id}`,
    ).pipe(map((v)=> v.body));
  }


  queryVostroRecord(params: VostroRecordQueryParams) {
    return this.http.post<VostroRecords>(
        this.remitApiUrl + '/staff/vostro-records',
        params,
    );
  }

  vostroRecordVerify(id: string): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/vostro-record/${id}/verify`,
        null,
    );
  }


  vostroRecordUndoVerified(id: string): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/vostro-record/${id}/undo-verify`,
        null,
    );
  }

  updateVostroRecord(
      id: string, params: Partial<VostroCreateFields>):
      Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/vostro-record/${id}`,
        params,
    );
  }
}
