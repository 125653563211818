import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';

export interface QueryCampaignParams{
  pageNumber?: number |null
  pageSize?: number |null
  isActive?: 'Y' | 'N' | '' | null
}

export interface Campaigns{
  totalData: number
  body: Campaign[]
}

export type Campaign = CampaignForm &{
  id: string
  discountCurrency: string
  isActive: 'Y' | 'N'
  timeZone: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface CampaignForm{
  name: string | null
  description: string | null
  discount: string | null
  campaignStart: string | null
  campaignEnd: string | null
  daysOfMonth: number[] | null
  daysOfWeek: number[] | null
  minutesFromMidnightStart: number | null
  minutesFromMidnightEnd: number | null
  algorithm: CampaignAlgorithms | null
  isTaxed: 'Y' | 'N' | ''
}

export type CampaignAlgorithms = 'alwaysOn' | 'specialPromo' | 'vip'

@Injectable({
  providedIn: 'root',
})
export class RemitPromoCampaignService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  createNewCampaign(newCampaign: Partial<CampaignForm> ):
   Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/promo-campaign',
        newCampaign,
    );
  }

  updateCampaign(id: string, campaign: Partial<CampaignForm> ):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/promo-campaigns/' + id,
        campaign,
    );
  }


  queryCampaigns(params: QueryCampaignParams): Observable<Campaigns> {
    return this.http.post<Campaigns>(
        this.remitApiUrl + '/staff/promo-campaigns',
        params,
    );
  }

  getCampaign(id: string): Observable<Campaign> {
    return this.http.get<{body: Campaign}>(
        this.remitApiUrl + '/staff/promo-campaigns/'+id,
    ).pipe(map((v)=>v.body));
  }


  setCampaignActive(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/promo-campaigns/${id}/activate`,
        {},
    );
  }


  setCampaignDisable(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/promo-campaigns/${id}/disable`,
        {},
    );
  }

  campaignAlgorithmNamingMap(): Record<CampaignAlgorithms, string> {
    return {
      'alwaysOn': 'Nyala Terus',
      'specialPromo': 'Selang Seling',
      'vip': 'VIP Only',
    };
  }

  buildDetailsText(campaign: Campaign): string {
    let daysOfMonthText = 'every day of month';
    if (campaign.daysOfMonth?.length ?? 0 > 0) {
      daysOfMonthText =
      `only on ${campaign.daysOfMonth?.join(', ')} of the month`;
    }

    const daysOfWeekMap = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let daysOfWeekText = 'every day of week';
    if (campaign.daysOfWeek?.length ?? 0 > 0) {
      daysOfWeekText =
      `only on ${campaign.daysOfWeek?.
          map((day) => daysOfWeekMap[day]).join(', ')}`;
    }

    const startHour =
    String(Math.floor(
        (campaign.minutesFromMidnightStart ??0) / 60)).
        padStart(2, '0');
    const startMinutes =
    String((campaign.minutesFromMidnightStart??0) % 60).
        padStart(2, '0');
    const endHour =
    String(Math.floor((campaign.minutesFromMidnightEnd??0) / 60)).
        padStart(2, '0');
    const endMinutes =
    String((campaign.minutesFromMidnightEnd??0) % 60).
        padStart(2, '0');

    return `${ daysOfMonthText}. ${daysOfWeekText}. only from ${startHour}:${
      startMinutes} to ${endHour}:${endMinutes}.`;
  }
}
