import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ShopItemLookupComponent} from './shop-item-lookup.component';

@Injectable({
  providedIn: 'root',
})
export class ShopItemLookupService {
  constructor(public dialog: MatDialog) {}

  shopItemLookup(): Observable<string | undefined> {
    const dialogRef = this.dialog.open<
    ShopItemLookupComponent, string, string >(
        ShopItemLookupComponent,
        {
          width: '600px',
        },
    );
    return dialogRef.afterClosed();
  }
}
