// eslint-disable max-len
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  EMPTY,
  Observable,
  catchError,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {Fee} from './order-fees.service';
import {RemitMember} from './remit-member.service';
import {
  RemitRequiredCommentDialogComponent,
  RemitRequiredCommentDialogData,
} from './remit-required-comment-dialog/remit-required-comment-dialog.component';

export interface PaginationParams {
  pageSize?: number;
  pageNumber?: number;
}

export type OrderQueryParams = PaginationParams & {
  arcNumber?: string | null;
  phoneNumber?: string | null;
  userId?: string | null;
  transactionIds?: string[];
  paymentCodes?: string[];
  hasPaid?: 'Y' | 'N' | '' | null;
  hasReported?: 'Y' | 'N' | '' | null;
  hasTransferred?: 'Y' | 'N' | '' | null;
  hasProblem?: 'Y' | 'N' | '' | null;
  hasInvoiced?: 'Y' | 'N' | '' | null;
  bankReportValidFor?: string | null;
  bankReportValidForCurrentTime?: string;
  paidTimeStart?: string | null;
  paidTimeEnd?: string | null;
  transferredAtStart?: string | null;
  transferredAtEnd?: string | null;
  createdAtStart?: string | null;
  createdAtEnd?: string | null;
  bankReportId?: string;
  bankReportBankId?: string | null;
  bankReportIds?: string[];
  bankCode?: string | null;
  excludeBankCode?: string | null;
  transferMethod?: string;
  arcExpiredDate?: string | null;
  isOnHold?: 'Y' | 'N' | '' | null;
  einvoiceNo?: string | null;
  userStatus?: string | null;
  userStatuses?: string[] | null;
  sortBy?: string | null;
  sortDirection?: number | null;
  beneficiaryAccountNumber?: string | null;
  shopCode?: string | null;
  shopCodes?: string[] | null;
  excludedShopCodes?: string[] | null;
  paymentMethod?:number|null;
  einvoiceAwardType?:number|null;
  einvoiceAwardStatus?:number|null;
  isLoanAgree?: 'Y' | 'N' | '' | null;
};

export interface RemitInvoices {
  body: RemitInvoice[];
  totalData: number;
}

export interface RemitInvoice {
  transactionId: string;
  customEinvoiceId?: string;
  userId: string;
  paymentCVSCode: string;
  paymentCVSCodeUrl: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
  paidAt?: string;
  expiredTime: string;
  totalPaymentNTD: string;
  beneficiaryName: string;
  beneficiaryBankCode: string;
  beneficiaryAccountNumber: string;
  beneficiaryDateOfBirth?: string;
  beneficiaryInquiryMethod?: string;
  bankName: string;
  fees: Fee[];
  promos: Promo[];
  amountNTD: string;
  foreignAmount: string;
  foreignCurrency: string;
  rate: string;
  usePoint: string;
  sender?: RemitMember;
  onHoldAt?: string;
  onHoldBy?: string;
  releasedAt?: string;
  releasedBy?: string;
  transferredAt?: string;
  transferredBy?: string;
  transferMethod?: string;
  transferProblem?: string;
  transferRefNumber?:string;
  reportedAt?: string;
  reportedBy?: string;
  processedAt?: string;
  processedBy?: string;
  submitStatus?: string;
  refTradeNo?: string;
  isOnHold?: 'Y' | 'N' | '';
  userStatus?: string;
  einvoiceNo?: string;
  einvoiceDate?: string;
  einvoiceMethod?: string;
  einvoiceAmount?: string;
  einvoiceAwardStatus?: number;
  einvoiceAwardType?: number;
  einvoiceAwardTypeName?: string;
  einvoiceAwardStatusName?: string;
  bankReportId?: string;
  bankReportBankId?: string;
  shopCode?: string;
  note?: string;
  isLoanAgree?:boolean;
}

export interface Promo {
  name: string;
  amountNTD: string;
}

export interface Comments {
  totalData: number;
  body: Comment[];
}

export interface Comment {
  id: string;
  invoiceId: string;
  authorId: string;
  isDeleted: 'Y' | 'N' | '';
  content: string;
  createdAt: string;
}

export interface UserBeneficiary {
  id: string;
  bankCode: string;
  accountNumber: string;
  accountName: string;
  status: string;
  lastInquiryAt: string;
  createdAt: string;
  createdBy: string;
  userId: string;
  isDeleted: boolean;
  updatedAt: string;
  updatedBy: string;
  inquiryMethod: string;
  dateOfBirth?: string;
  disabled: boolean;
  reason?: string;
}

export interface UserBeneficiaries {
  body: UserBeneficiary[];
  totalData: number;
}

export interface RemitInvoiceEvent {
  data: RemitInvoice;
  aggregationId: string;
  id: string;
  eventType: string;
}

export interface RemitInvoiceEvents {
  body: RemitInvoiceEvent[];
  totalData: number;
}

export interface ReportElectronicInvoicingParams {
  customEinvoiceId?: string;
}

export interface RemitCheckoutParams {
  beneficiaryId: string;
  calculateFrom: string;
  amountNTD: string;
  userId: string;
  shopCode: string;
  amountFee: string;
  amountDiscount: string;
  paymentMode: number | null;
}

export interface RemitOrderPrecheckoutResponse {
  message: string;
  body: RemitPrecheckoutResult;
}

export interface RemitReceiptMessage {
  message: string;
  body: string;
}

export interface RemitPrecheckoutResult {
  amountNTD: string;
  foreignAmount: string;
  rate: string;
  totalPaymentNTD: string;
  beneficiaryName: string;
  beneficiaryBankCode: string;
  beneficiaryAccountNumber: string;
  canCheckout: 'Y' | 'N';
  errorMessage?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RemitInvoiceService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) {}

  queryOrders(params: OrderQueryParams): Observable<RemitInvoices> {
    return this.http.post<RemitInvoices>(
        this.remitApiUrl + '/staff/remit-invoices',
        params,
    ).pipe(
        map((response) => {
          response.body = response.body.map((invoice) => {
            if (invoice.isLoanAgree === true) {
              return {
                ...invoice,
                beneficiaryBankCode: '002',
                beneficiaryName: 'Pt Komala Santoso Indonesia',
                beneficiaryAccountNumber: '007101004075309',
                bankName: 'BRI',
              };
            }
            return invoice;
          });
          return response;
        }),
    );
  }

  queryOrdersExport(params: OrderQueryParams): Observable<HttpResponse<Blob>> {
    const obs$ = this.http
        .post<Blob>(this.remitApiUrl + '/staff/remit-invoices/excel', params, {
          observe: 'response',
          responseType: 'blob' as 'json',
        })
        .pipe(
            tap((blob: HttpResponse<Blob>) => {
              if (blob.body) {
                const url = window.URL.createObjectURL(blob.body);

                const currentDate = new Date(); // get current date and time
                const dateStr = currentDate.toISOString().replace(/:/g, '-');

                const link = document.createElement('a');
                link.href = url;
                link.download = `remit-invoice-${dateStr}.xlsx`;
                link.click();
              }
            }),
        );

    return obs$;
  }

  getOrder(id: string): Observable<RemitInvoice> {
    return this.http
        .get<{body: RemitInvoice}>(
            this.remitApiUrl + '/staff/remit-invoice/' + id,
        )
        .pipe(map((v) => v.body));
  }

  setTransferSuccess(id: string): Observable<RemitApiResponse> {
    return this.requireComment(id, 'Set Transaction as Transferred').pipe(
        switchMap((comment) => {
          if (!comment) return EMPTY;
          return this.http.post<RemitApiResponse>(
              this.remitApiUrl + `/staff/remit-invoice/${id}/transferred`,
              {comment},
          );
        }),
    );
  }

  setTransferProblemResolved(id: string): Observable<RemitApiResponse> {
    return this.requireComment(id, 'Set to Paid').pipe(
        switchMap((comment) => {
          if (!comment) return EMPTY;
          return this.http.post<RemitApiResponse>(
              this.remitApiUrl + `/staff/remit-invoice/${id}/revert`,
              {comment},
          );
        }),
    );
  }

  setInvoiceOnHold(id: string): Observable<RemitApiResponse> {
    return this.requireComment(id, 'Hold transacion').pipe(
        switchMap((comment) => {
          if (!comment) return EMPTY;
          return this.http.post<RemitApiResponse>(
              this.remitApiUrl + `/staff/remit-invoice/${id}/on-hold`,
              {comment},
          );
        }),
    );
  }

  setInvoiceRelease(id: string): Observable<RemitApiResponse> {
    return this.requireComment(id, 'Release transaction').pipe(
        switchMap((comment) => {
          if (!comment) return EMPTY;
          return this.http.post<RemitApiResponse>(
              this.remitApiUrl + `/staff/remit-invoice/${id}/release`,
              {comment},
          );
        }),
    );
  }

  setRefundedInvoice(id: string): Observable<RemitApiResponse> {
    return this.requireComment(id, 'Set Transaction Refunded').pipe(
        switchMap((comment) => {
          if (!comment) return EMPTY;
          return this.http.post<RemitApiResponse>(
              this.remitApiUrl + `/staff/remit-invoice/${id}/refunded`,
              {comment},
          );
        }),
    );
  }
  resolveDeadlock(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/resolve-deadlock`,
        null,
    );
  }

  refreshTransferStatus(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/refresh`,
        null,
    );
  }
  reportElectronicInvoicing(
      id: string,
      params?: ReportElectronicInvoicingParams,
  ): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/e-invoice`,
        params,
    );
  }

  batchGenerateElectronicInvoiceNumber(
      ids: string[],
  ): Observable<RemitApiResponse[]> {
    if (!ids) {
      return of();
    }
    if (ids.length <= 0) {
      return of();
    }
    return this.queryOrders({
      pageNumber: 0,
      pageSize: ids.length,
      transactionIds: ids,
      hasPaid: 'Y',
    }).pipe(
        switchMap((invoices) => {
          if (invoices.body.length != ids.length) {
            return throwError(() => new Error('Incomplete or missing invoices.'));
          }
          return forkJoin(
              ids.map((id) =>
                this.reportElectronicInvoicing(id).pipe(
                    catchError((err) => {
                      console.log(err);
                      return of({
                        error: err,
                      });
                    }),
                ),
              ),
          );
        }),
    );
  }

  createInvoiceComment(
      id: string,
      comment: string,
  ): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/comment`,
        {
          content: comment,
        },
    );
  }

  deleteInvoiceComment(invoiceId :string, commentId: string):
  Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl+`/staff/remit-invoice/${invoiceId}/comment/${commentId}`,
        {},
    );
  }

  queryInvoiceComments(
      id: string,
      params: PaginationParams,
  ): Observable<Comments> {
    return this.http.post<Comments>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/comments`,
        params,
    );
  }

  getInvoiceEvents(params: PaginationParams): Observable<RemitInvoiceEvents> {
    return this.http.post<RemitInvoiceEvents>(
        this.remitApiUrl + `/staff/remit-invoices/events`,
        params,
    );
  }

  queryUsersBeneficiary(params: {
    pageNumber: number;
    pageSize: number;
    userId: string;
  }): Observable<UserBeneficiaries> {
    // get user's beneficiaries given trx has problem
    return this.http.post<UserBeneficiaries>(
        this.remitApiUrl + '/staff/beneficiaries',
        params,
    );
  }

  getBeneficiary(id: string) :Observable<UserBeneficiary> {
    return this.http.get<{body: UserBeneficiary}>(
        this.remitApiUrl+`/staff/beneficiaries/${id}`,
    ).pipe(
        map((v)=>v.body),
    );
  }

  disableBeneficiary(id: string, params:{
    reason: string
  }):Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl+`/staff/beneficiaries/${id}/disabled`,
        params,
    );
  }

  enableBeneficiary(id: string):Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl+`/staff/beneficiaries/${id}/enable`,
        {},
    );
  }

  amendTransactionBeneficiary(
      id: string,
      benefId: string,
  ): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/beneficiary/${benefId}`,
        null,
    );
  }

  approveTransaction(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/paid`,
        {},
    );
  }

  cancelTransaction(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/cancel`,
        {},
    );
  }

  changeTransactionAmount(id: string, amountNTD: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/change-amount`,
        {
          amountNTD: amountNTD,
        },
    );
  }
  sendReceiptSMS(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/send-receipt`,
        {},
    );
  }

  getReceiptMessage(id: string): Observable<RemitReceiptMessage> {
    return this.http.get<RemitReceiptMessage>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/receipt-message`,
    );
  }

  checkInvoiceAward(id: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/remit-invoice/${id}/check-award`,
        {},
    );
  }

  batchCheckInvoiceAward(ids: string[]): Observable<RemitApiResponse[]> {
    return forkJoin(
        ids.map((id)=> this.checkInvoiceAward(id).pipe(
            catchError((err) => {
              console.log(err);
              return of({
                error: err,
              });
            }),
        )),
    );
  }

  private requireComment(
      transactionId: string,
      action: string,
  ): Observable<string | undefined> {
    return this.dialog
        .open<
        RemitRequiredCommentDialogComponent,
        RemitRequiredCommentDialogData,
        string
      >(RemitRequiredCommentDialogComponent, {
        data: {
          transactionId: transactionId,
          action: action,
        },
      })
        .afterClosed();
  }

  userStatusMapping(): Observable<Record<string, string>> {
    // get user's beneficiaries given trx has problem
    return this.http
        .get<{body: Record<string, string>}>(
            this.remitApiUrl + '/staff/remit-invoice-user-status',
        )
        .pipe(map((v) => v.body));
  }

  processStatusMapping(): Observable<Record<string, string>> {
    // get user's beneficiaries given trx has problem
    return this.http
        .get<{body: Record<string, string>}>(
            this.remitApiUrl + '/staff/remit-invoice-submit-status',
        )
        .pipe(map((v) => v.body));
  }

  orderPrecheckout(
      params: Partial<RemitCheckoutParams>,
  ): Observable<RemitOrderPrecheckoutResponse> {
    return this.http.post<RemitOrderPrecheckoutResponse>(
        this.remitApiUrl + '/staff/precheckout',
        params,
    );
  }
  orderCheckout(
      params: Partial<RemitCheckoutParams>,
  ): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/checkout',
        params,
    );
  }


  get userStatusDictionary(): Record<string, string> {
    return {
      '100': 'Waiting Payment',
      '200': 'Paid',
      '300': 'Processed',
      '400': 'Done',
      '500': 'Expired',
      '600': 'Problem',
      '700': 'Refunded',
      '800': 'Cancelled',
      '': 'Unknown',
    };
  }

  get einvoiceAwardTypeName() : Record<number, string> {
    return {
      0: '-',
      1: '六獎 - 2百元',
      2: '五獎 - 1千元',
      3: '四獎 - 4千元',
      4: '三獎 - 1萬元',
      5: '二獎 - 4萬元',
      6: '頭獎 - 20萬元',
      7: '特獎 - 200萬元',
      8: '特別獎 - 1,000萬元',
      9: '雲端 - 2千元',
      10: '雲端 - 100萬元',
      11: '雲端 - 5百元',
      12: '雲端 - 8百元',
    };
  }


  get einvoiceAwardStatusName() : Record<number, string> {
    return {
      0: 'Belum di Undi',
      1: 'Tidak Menang',
      2: 'Menang',
      3: 'Tidak di Undi',
    };
  }
}
