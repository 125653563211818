import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, from, lastValueFrom, throwError} from 'rxjs';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';


@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService, private router: Router,
  ) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler) {
    return from(this.handle<T>(req, next)).pipe(
        catchError((err, caught) => {
          if (err?.status === 401) {
            this.router.navigate(['/login'], {
              queryParams: {redirectUrl: this.router.routerState.snapshot.url},
            });
          }
          if (err.error?.error) {
            return throwError(() => Error(err.error?.error ?? ''));
          }
          return throwError(() => err);
        }),
    );
  }

  private async handle<T>(req: HttpRequest<T>, next: HttpHandler) {
    let authToken = await this.auth.getToken() ?? '';

    // retry getting token once
    if (!authToken) {
      await new Promise((f) => setTimeout(f, 500));
      authToken = await this.auth.getToken() ?? '';
    }

    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer '+ authToken,
      },
    });
    return await lastValueFrom(next.handle(authReq));
  }
}
