import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class MsjhFontTtfResolver {
  resolve(): Promise<string> {
    return import('./msjh-font.data').then((mod) => mod.msjhFont);
  }
}
