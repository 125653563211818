import {map, Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {REMIT_BASE_URL} from '../config';

interface RateResponse {
  rate: string;
}

export interface RateRecord{
  id: string;
  rate: string;
  createdAt: string;
  createdBy: string;
}

export interface RateRecords{
  body: RateRecord[]
  totalData: number;
}

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) {}


  getNtdIdrExchangeRate(): Observable<number> {
    const result = this.http
        .get<unknown>(this.remitApiUrl+`/staff/rate`)
        .pipe(
            map((data) => {
              if (typeof (data as RateResponse).rate === 'string') {
                return parseInt((data as RateResponse).rate);
              }
              throw Error('Can\'t find field rate value.');
            }),
        );
    return result;
  }

  queryRateChanges(params:{
    pageNumber?: number | null,
    pageSize?: number | null,
    sortBy?: string | null,
    sortDirection?: number | null,
  }): Observable<RateRecords> {
    const result = this.http
        .post<RateRecords>(this.remitApiUrl+`/staff/rates`,
            params,
        );
    return result;
  }

  modifyNtdIdrExchangeRate(rate: number) {
    const data = {
      rate: rate.toString(),
    };
    const result = this.http
        .post<unknown>(this.remitApiUrl+`/staff/rate`, data);
    return result;
  }
}
