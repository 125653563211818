import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map, switchMap} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {Comments, PaginationParams} from './remit-invoice.service';

export interface MemberQueryParams {
  pageSize?: number
  pageNumber?: number
  arcNumber?: string | null;
  phoneNumber?: string| null;
  name?: string| null;
  sortDirection?: number| null;
  sortBy?: string| null;
  memberIds?: string[] | null;
}

export interface RemitMemberCreateForm{
  name: string
  phoneNumber: string
  dateOfBirth: string
  arcExpiredDate: string
  arcNo: string
  arcFrontImage: string
  arcBackImage: string
  selfieImage: string
  userSignatureImage: string
  passportNumber: string
}

export interface RemitMembers{
  body?: RemitMember[]
  totalData: number
}

export type RemitMember = RemitMemberBasicInfo & RemitMemberUpdatableInfo

export type RemitMemberBasicInfo = {
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  referralCode?: string
  referredBy?: string
} & RemitMemberUpdatableArray

export interface RemitMemberUpdatableArray{
  phoneNumbers: string[]
  attachments: string[]
}

export interface RemitMemberUpdatableInfo{
  name: string
  arcNo: string
  phoneNumber: string
  arcOld: string
  arcType: string
  arcExpiredDate: string
  arcIssueDate: string
  dateOfBirth: string
  sex: string
  address: string
  isBlacklist: string
  blacklistReason?: string
  limitTxnPerMonth: string
  limitNTDPerMonth: string
  limitNTDPerYear: string
  arcFrontImage: string
  arcBackImage: string
  selfieImage: string
  userSignatureImage: string
  arcBarcodeNumber: string;
  nationality: string;
  institution: string;
  passportNumber: string;
}

export interface RemitBenefCreateForm{
  bankCode: string
  accountNumber: string
  dateOfBirth: string
}

export interface MemberOutboxQuery {
  pageNumber?: number
  pageSize?: number
  memberId?: string
}

export interface OutboxMembers {
  body: OutboxMember[];
  totalData: number;
}

export interface OutboxMember {
  id: string;
  aggregationId: string;
  changes: Change[];
  before: MemberDetails;
  after: MemberDetails;
  status: string;
}

export interface Change {
  type: string;
  path: string[];
  from: string | null;
  to: string | null;
}

export interface MemberDetails {
  id: string;
  loginId: string;
  name: string;
  arcNo: string;
  phoneNumber: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  phoneNumbers: string[] | null;
  arcOld: string;
  arcType: string;
  arcExpiredDate: string;
  dateOfBirth: string;
  sex: string;
  address: string;
  isBlacklist: string;
  blacklistReason: string;
  limitTxnPerMonth: string;
  limitNTDPerMonth: string;
  limitNTDPerYear: string;
  arcFrontImage: string;
  arcBackImage: string;
  selfieImage: string;
  userSignatureImage: string;
  attachments: any | null;
  version: string;
  arcBarcodeNumber: string;
  arcIssueDate: string | null;
  nationality: string;
  institution: string;
  passportNumber: string;
  referralCode: string;
  referredBy: string;
  passportImg: string;
  passportType: string;
  placeOfBirth: string;
  passportDateOfIssue: string | null;
  passportDateOfExpiry: string | null;
  passportRegistrationNo: string;
  passportIssuingOffice: string;
  vipExpiredAt: string | null;
  isDeleted: boolean;
  registrationSource: string;
}

export interface PushNotification {
  message: string;
  memberId: string;
}

export interface Notification {
  id: string
  createdAt: string;
  createdBy: string;
  title: string;
  message: string;
  isHidden: boolean;
  device: DeviceInfo[];
}

export interface DeviceInfo {
  deviceToken: string;
  deviceId: string;
}

export interface NotificationQueryParams {
  pageNumber?:number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?:number;
  userId?: string;
}

export interface Notifications{
  body?: Notification[]
  totalData: number
}

@Injectable({
  providedIn: 'root',
})
export class RemitMemberService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  queryNotification(params:NotificationQueryParams ):Observable<Notifications> {
    return this.http.post<Notifications>(this.remitApiUrl+'/staff/notifications',
        params,
    );
  }
  pushNotification(params : PushNotification):Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl+'/staff/push-notification',
        params,
    );
  }
  queryMembers(params: MemberQueryParams,
  ): Observable<RemitMembers> {
    return this.http.post<RemitMembers>(
        this.remitApiUrl + '/staff/members',
        params,
    );
  }

  getMember(id: string): Observable<RemitMember> {
    return this.http.get<{body: RemitMember}>(
        this.remitApiUrl + '/staff/member/' + id,
    ).pipe(map((v)=> v.body));
  }
  updateMember(id: string, data: Partial<RemitMemberUpdatableInfo>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/member/' + id,
        data,
    );
  }

  detachMemberPhone(id: string):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/member/${id}/detach-phone-number`,
        null,
    );
  }
  pushAttachment(id:string, url: string):
  Observable<RemitApiResponse> {
    return this.getMember(id).pipe(
        switchMap((member)=>{
          const existing = member.attachments ?? [];
          return this.http.put<RemitApiResponse>(
              this.remitApiUrl+`/staff/member/${id}`,
              {
                attachments: [
                  ...existing,
                  url,
                ],
              },
          );
        }),
    );
  }
  removeAttachmentAt(id:string, position: number):
  Observable<RemitApiResponse> {
    return this.getMember(id).pipe(
        switchMap((member)=>{
          return this.http.put<RemitApiResponse>(
              this.remitApiUrl+`/staff/member/${id}`,
              {
                attachments: member.attachments?.filter(
                    (_, index) => index != position),
              },
          );
        }),
    );
  }


  createMemberComment(id: string, comment: string)
  : Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/member/${id}/comment`,
        {
          content: comment,
        },
    );
  }

  queryMemberComments(id: string, params: PaginationParams) :
  Observable<Comments> {
    return this.http.post<Comments>(
        this.remitApiUrl + `/staff/member/${id}/comments`,
        params,
    );
  }

  deleteMemberComment(memberId: string, commentId: string)
  : Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + `/staff/member/${memberId}/comment/${commentId}`,
    );
  }

  createMember(data: Partial<RemitMemberCreateForm>):
  Observable<RemitMemberCreateForm> {
    return this.http.post<RemitMemberCreateForm>(
        this.remitApiUrl + `/staff/member`,
        data,
    );
  }

  addBenef(userId: string, data: Partial<RemitBenefCreateForm>):
  Observable<RemitBenefCreateForm> {
    return this.http.post<RemitBenefCreateForm>(
        this.remitApiUrl + `/staff/beneficiary/${userId}`,
        data,
    );
  }

  queryOutboxMembers(params: MemberOutboxQuery): Observable<OutboxMembers> {
    return this.http.post<OutboxMembers>(
        this.remitApiUrl + `/staff/member-outboxes`,
        params,
    );
  }
}
