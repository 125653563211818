import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REMIT_BASE_URL} from './config';


export interface ShowLoanConsentRequest {
  showLoanConsent: boolean;
}

export interface ShowLoanConsentResponse {
  message: string;
  body?: ShowLoanConsent;
}

export interface ShowLoanConsent {
  id?: string;
  createdAt?: string;
  createdBy?: string;
  showLoanConsent?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LoanDisbursementService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  createLoanConsent(request: ShowLoanConsentRequest):
    Observable<ShowLoanConsentResponse> {
    return this.http.post<ShowLoanConsentResponse>(
        `${this.remitApiUrl}/staff/show-loan-consent`,
        request,
    );
  }


  getLatestLoanConsent(): Observable<ShowLoanConsentResponse> {
    return this.http.get<ShowLoanConsentResponse>(
        `${this.remitApiUrl}/staff/show-loan-consent/latest`,
    );
  }
}
