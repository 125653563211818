import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, map, of} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {RemitPublicImageUploaderService} from './remit-public-image-uploader.service';


export interface RemitShopItem {
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  version: string
  name: string
  description: string
  price: number
  capitalPrice?: number
  isLifePrice?:number
  agentPrice?: number
  image: string
  vipDurationDays: number
  isDeleted: boolean
  shippingCost?: number;
  itemType?: string;
  digitalProductDetails?: DigitalProductDetails
  soldOut?:boolean;
  notes?: string
  available?:boolean;
  tags?: string[]
  barcodes?: string[]
  voucherCount?: number
  originalPrice?: number
  requiredFields?: RequiredFields[]
  shortDescription?: string
  sku?: string
  variant?: ShopItemVariant[]
}

export interface ShopItemVariant{
  name?: string
  sku?: string
  soldOut?: boolean
}

export interface RequiredFields{
  name: string
  label: string
  type: string
  isRequired: boolean
  minimumLength?: number
  maximumLength?: number
}

export interface DigitalProductDetails{
  id?: string | null
  type?: string | null
}

export interface RemitShopItemCreateForm{
  name: string
  description: string
  price: number
  image: string
  vipDurationDays: number
  shippingCost: number;
  itemType: string;
  digitalProductDetails?: DigitalProductDetails | null
  soldOut?: boolean
  tags?: string[]
  voucherCount?: number
  originalPrice?: number;
  shortDescription?: string
  sku?: string
  variant?: ShopItemVariant[]
  notes?: string
  capitalPrice?: number
  isLifePrice?:number,
  agentPrice?:number,
  barcodes?: string[]
}

interface RemitShopItemCreateWriteModel{
  name: string
  description: string
  price: number
  image: string
  vipDurationDays: number
  shippingCost: number;
  itemType: string;
  digitalProductDetails?: DigitalProductDetails | null
  soldOut?: string
  tags?: string[]
  voucherCount?: number
  originalPrice?: number
  shortDescription?: string
  sku?: string
  variant?: ShopItemVariant[]
  notes?: string
  capitalPrice?: number
  agentPrice?:number
  isLifePrice?:number
  barcodes?: string[]
}

export interface ShopItemQueryParams{
  pageNumber?: number | null
  pageSize?: number | null
  name?: string | null
  search?: string | null
  itemType?: string | null
  isDeleted?: 'Y' | 'N' | null
  sku?: string | null
}

export interface ShopOrderItem {
  itemId: string;
  itemType?: string;
  bundlingId: string;
  quantity: number;
  orderPrice: number;
  name: string;
  digitalItemDetail?: DigitalItemDetail;
  transactionStatus: string;
  transactionStatusName?: string | null;
  transactionMessage?: string | null;
  sku?: string;
  variantName: string;
}

export interface ShopOrderItemUpdate{
  quantity?: number
  price?:number
  variantIndex?: number
}

export interface DigitalItemDetail {
  refId: string;
  destinationNumber: string;
  accountName?: string;
  adminFee?: number;
  amountDue?:number;
  billId?: string;
  notes?:string;
  period?:string;
  totalAmount?:number;
}

export interface Precheckout {
  items?: ShopOrderItem[];
  totalPrice?: number;
  canCheckout?: 'Y'|'N'
  errorMessage?: string
  errorItemIndex?:number
}

export interface ShopOrder {
  id: string;
  customerId: string;
  centralCustomerId?: string;
  itemsView: ShopOrderItem[];
  name: string;
  phoneNumber: string;
  extraPhoneNumbers: string[];
  address: string;
  totalPrice: number;
  note: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  isDeleted: boolean;
  shippedAt?: string;
  shippedBy: string;
  shippingNumber: string;
  paidAt?: string;
  paidBy: string;
  refTradeNo: string;
  paymentCvsCode: string;
  paymentCvsCodeUrl: string;
  expiredAt?: string;
  orderStatusName?: string;
  orderStatus?: string;
  invoicedAt?: string;
  invoiceNumber?: string;
  invoiceMethod?: string;
  invoiceAmount?: number;
  paymentMethodName?: string;
  shippingProvider?: number;
  shippingProviderName?:string;
  shopCode?: string;
  version: string;
  reason?: string;
  canShipAt?: string;
  subTotal?: number;
  fees?: ShopOrderFee[];
  isExported: boolean;
  deliveryType?: number
}

export interface ShopOrderFee{
  id: string
  name: string
  amount: number
}

export interface QueryResult<T>{
  body: T[]
  totalData: number
}

export interface GetResult<T>{
  body: T
  message: string
}

export interface BaseQueryParams{
  pageNumber?: number | null
  pageSize?: number | null
  sortBy?: string | null
  sortDirection?: number | null
  search?: string | null
}

export type ShopOrderQueryParams = BaseQueryParams & {
  orderStatus?: string | null
  isDeleted?: 'Y' | 'N' | null
  shopCode?: string | null
  userId?: string | null
  createdAtStart?: string | null
  createdAtEnd?: string | null
  shippedAtStart?: string | null
  shippedAtEnd?: string | null
  tags?: string[] | null
  itemId?: string | null
  paymentMethod?: number | null
  shippingProvider?: number | null
  itemName?:string |null
  customerGroupId?:string | null
}

export type ShopItemTagQueryParams = BaseQueryParams

export interface ShopItemType {
  id: string
  name: string
}

export interface PrepaidProduct {
  productCode: string;
  productDescription: string;
  productNominal: string;
  productDetails: string;
  productPrice: number;
  productType: string;
  activePeriod: string;
  status: string;
  iconUrl: string;
  productCategory: string;
}

export interface PostpaidProduct {
  productCode: string;
  productType: string;
  productCategory: string;
  status: number;
  productDescription: string;
  productNominal: string;
  productDetails: string;
  productPrice: number;
  activePeriod: string;
  iconUrl: string;
  name: string;
  fee: number;
  commission: number;
}

export type BundlingOrderAction = 'cancelled' |
'completed'| 'rejected' | 'returned'| 'einvoice' |
'undo-cancelled' | 'undo-completed' |
'undo-delete' | 'undo-rejected' | 'undo-shipped' | 'undo-returned';

export type FeatureStatus = 'hide' | 'beta' | 'prod';


export interface ShopOrderItemSummary{
  body: ShopOrderItemSummaryItem[]
  totalData: number
  totalGross: number
  totalNet: number
  totalCapital: number
}

export interface ShopOrderItemSummaryItem{
  itemId: string
  itemName: string
  totalQuantity: string
  notes: number
  capitalPrice: number
  price: number
  sku: string
  totalGross: number
  totalNet: number
}

interface OrderItemSummaryQueryParams {
  pageNumber: number;
  pageSize: number ;
  shopCode: string | null;
  orderStatus: string | null;
  paymentMethod?: number | null;
  createdAtStart?: string | null;
  createdAtEnd?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  sku? : string | null;
  itemName: string;
  tags: string[];
}

export interface RemitShopBranchCreateForm {
  name: string
  code: string
  staffIds: string[]
  fapiaoStaffIds: string[]
  defaultRemitPaymentMode: number | null
}
export interface RemitShopBranch {
  id: string
  name: string
  code: string
  staffIds: string[]
  fapiaoStaffIds: string[]
  defaultRemitPaymentMode: number | null
  isDeleted: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}
export interface ShopBranchQueryParams {
  pageNumber?: number | null
  pageSize?: number | null
  name?: string | null
  code?: string | null
  isDeleted?: boolean | null
  getAllBranch?: boolean | null
}


export interface FeatureConfig {
  features: {
    bundlingHome: FeatureStatus;
    pay88Shop: FeatureStatus;
    shopHeimaoPay: FeatureStatus;
  }
  betaUids: string[];
}

export interface CategoryListing {
  categories?: Category[];
}

export interface Category {
  name: string;
  image: string;
  subCategories?: SubCategory[];
}

export interface SubCategory {
  name: string;
  image: string;
  items?: DiscoveryItem[];
}

export interface DiscoveryItem {
  id: string;
}


export interface ShopItemTagForm{
  name?: string
  description?: string
}

export interface ShopItemTag{
  id: string
  name: string
  description?: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface RemitOrderCreation{
  customerId?: string
  centralCustomerId?: string
  items: Partial<RemitOrderItem>[]
  note?: string
  address?: string
  paymentMethod?: number
  shippingProvider?: number
  shopCode?: string
  name?: string
  arcNumber?: string
  phoneNumber?: string
  extraPhoneNumbers?: string[]
  canShipAt?: string | null
  deliveryType?: number
  shippingCost?:number
}

export interface RemitOrderItem{
  itemId: string
  quantity: number
  digitalItemDetail?: Map<string, string>
  variantName?: string
  orderPrice?: number
}


export interface ShopOrderSummary{
  date: string
  total: number
  count: number
  perPaymentMethod?: ShopOrderPerPayment[]
}

export interface ShopOrderPerPayment{
  id: number
  count: number
  total: number
  name?: string
}


@Injectable({
  providedIn: 'root',
})
export class RemitShopService {
  constructor(
    private http: HttpClient,
    private publicImageUploader: RemitPublicImageUploaderService,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  createShopItem(data: Partial<RemitShopItemCreateForm>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/bundling',
        this.buildUpdateWriteModel(data),
    );
  }

  updateShopItem(id: string, data: Partial<RemitShopItemCreateForm>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling/${id}`,
        this.buildUpdateWriteModel(data),
    );
  }

  private buildUpdateWriteModel(data: Partial<RemitShopItemCreateForm>):
  Partial<RemitShopItemCreateWriteModel> {
    const writeModel: Partial<RemitShopItemCreateWriteModel> = {
      name: data.name,
      description: data.description,
      price: data.price,
      image: data.image,
      vipDurationDays: data.vipDurationDays,
      shippingCost: data.shippingCost,
      itemType: data.itemType,
      digitalProductDetails: data.digitalProductDetails,
      tags: data.tags,
      voucherCount: data.voucherCount,
      originalPrice: data.originalPrice,
      shortDescription: data.shortDescription,
      sku: data.sku,
      variant: _.cloneDeep(data.variant),
      notes: data.notes,
      capitalPrice: data.capitalPrice,
      barcodes: data.barcodes,
      isLifePrice: data.isLifePrice,
      agentPrice: data.agentPrice,
    };


    if (data.soldOut === true) {
      writeModel.soldOut = 'Y';
    }
    if (data.soldOut === false) {
      writeModel.soldOut = 'N';
    }


    return writeModel;
  }


  getShopItem(id: string): Observable<RemitShopItem> {
    return this.http.get<{body: RemitShopItem}>(
        this.remitApiUrl + `/staff/bundling/${id}`,
    ).pipe(
        map((v)=> v.body),
    );
  }

  queryShopItems(params: ShopItemQueryParams):
  Observable<QueryResult<RemitShopItem>> {
    return this.http.post<QueryResult<RemitShopItem>>(
        this.remitApiUrl + `/staff/bundlings`,
        params,
    );
  }

  deleteShopItem(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling/${id}`,
    );
  }

  getShopItemTypes(): Observable<ShopItemType[]> {
    return this.http.get<{body: ShopItemType[]}>(
        this.remitApiUrl + `/staff/item-types`,
    ).pipe(
        map((v)=> v.body),
    );
  }

  isBundlingFeatureEnabled(): Observable<boolean> {
    return this.http.get<{body: {isEnabled: boolean}}>(
        this.remitApiUrl + `/staff/bundling-feature/is-enabled`,
    ).pipe(
        map((v)=> v.body.isEnabled),
    );
  }

  setBundlingFeatureOn(): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling-feature/on`, {},
    );
  }

  setBundlingFeatureOff(): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling-feature/off`, {},
    );
  }

  getOrder(id: string): Observable<ShopOrder> {
    return this.http.get<{body:ShopOrder}>(
        this.remitApiUrl + `/staff/order/${id}`,
    ).pipe(
        map((v)=> v.body),
    );
  }

  queryOrders(params: Partial<ShopOrderQueryParams>):
  Observable<QueryResult<ShopOrder>> {
    return this.http.post<QueryResult<ShopOrder>>(
        this.remitApiUrl + `/staff/orders-view`,
        params,
    );
  }


  queryOrdersSummary(params: ShopOrderQueryParams):
  Observable<QueryResult<ShopOrderSummary>> {
    return this.http.post<QueryResult<ShopOrderSummary>>(
        this.remitApiUrl + `/staff/orders-summary`,
        params,
    );
  }

  queryOrderItemsSummary(params: Partial<OrderItemSummaryQueryParams>):
  Observable<ShopOrderItemSummary> {
    return this.http.post<ShopOrderItemSummary>(
        this.remitApiUrl + `/staff/orders-item-summary`,
        params,
    );
  }

  setOrderShipped(id: string, data: {
    shippingNumber: string,
  }):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}/shipped`,
        data,
    );
  }

  queryPrepaidProducts():Observable<PrepaidProduct[]> {
    return this.http.post<QueryResult<PrepaidProduct>>(
        this.remitApiUrl+`/staff/prepaid-products`,
        {},
    ).pipe(map((v)=>v.body));
  }

  queryPostpaidProducts():Observable<PostpaidProduct[]> {
    return this.http.post<QueryResult<PostpaidProduct>>(
        this.remitApiUrl+`/staff/postpaid-products`,
        {},
    ).pipe(map((v)=>v.body));
  }

  createOrder(data: Partial<RemitOrderCreation>):
  Observable<{
    body: ShopOrder
    message: string
    id: string
  }> {
    return this.http.post<{
      body: ShopOrder
      message: string
      id: string
    }>(
        this.remitApiUrl + `/staff/order`,
        data,
    );
  }

  precheckout(data: Partial<RemitOrderCreation>):
  Observable<{
    body: Precheckout
    message: string
  }> {
    return this.http.post<{
      body: Precheckout
      message: string
    }>(
        this.remitApiUrl + `/staff/order-preview`,
        data,
    );
  }

  updateOrder(id: string, data: Partial< {
    address: string,
    note: string,
    canShipAt: string,
    shippingProvider: number,
    extraPhoneNumbers: string[],
    name: string,
    phoneNumber: string,
    deliveryType: number,
  }>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}`,
        data,
    );
  }

  setOrder(id: string, action: BundlingOrderAction, params?: {
    reason?: string
  }):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}/${action}`,
        params,
    );
  }

  processPrepaidTransaction(orderId: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${orderId}/top-up`,
        {},
    );
  }

  openOrderInvoice(id: string):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}/einvoice`,
        {},
    );
  }

  deleteOrder(id: string):
  Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}`,
    );
  }

  getFeatureConfig(): Observable<FeatureConfig | undefined> {
    return this.http.get<{body?: FeatureConfig}>(
        this.remitApiUrl + `/staff/shop-features`,
    ).pipe(map((v)=>v.body));
  }

  getFeatureStates(): Observable<{id:FeatureStatus, name:string}[]> {
    return of([
      {id: 'hide', name: 'Hide - Not shown'},
      {id: 'beta', name: 'Beta - Shown to selected users only'},
      {id: 'prod', name: 'Production - Shown to all'},
    ]);
  }

  setFeatureStatus(data: Partial<FeatureConfig>): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/shop-features`,
        data,
    );
  }

  uploadPublicImage(file: File): Observable<string> {
    return this.publicImageUploader.uploadPublicImage(file);
  }

  updateCategoryListing(data: Partial<CategoryListing>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/category`,
        data,
    );
  }

  getCategoryListing(): Observable<CategoryListing> {
    return this.http.
        get<{body: CategoryListing}>(
            this.remitApiUrl + `/staff/categories`)
        .pipe(map((v)=> v.body));
  }

  changeDestinationNumber(orderId: string, index: number,
      data: any): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${index}`+
        `/destination-number`,
        data,
    );
  }

  changeOrderItem(orderId: string, index: number,
      itemId:string, data:any): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${index}`+
        `/change-order-item`,
        {
          itemId: itemId,
          ...data,
        },
    );
  }

  refreshPrepaidTransactionStatus(orderId: string, orderItemIndex: number):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${orderItemIndex}`+
        `/refresh`,
        {},
    );
  }

  refreshPostpaidTransactionStatus(orderId: string, orderItemIndex: number):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${orderItemIndex}`+
        `/refresh-postpaid`,
        {},
    );
  }

  prepaidTransactionTrigger(orderId: string, orderItemIndex: number):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${orderItemIndex}`+
        `/top-up`,
        {},
    );
  }

  postpaidTransactionTrigger(orderId: string, orderItemIndex: number):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${orderItemIndex}`+
        `/postpaid-top-up`,
        {},
    );
  }

  createNewItemTag(tag: ShopItemTagForm): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/item-tag',
        tag,
    );
  }

  getItemTag(id: string): Observable<ShopItemTag> {
    return this.http.get<{body: ShopItemTag}>(
        this.remitApiUrl + '/staff/item-tag/' + id,
    ).pipe(map((v)=> v.body));
  }

  updateItemTag(id: string, tag: ShopItemTagForm):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/item-tag/' + id,
        tag,
    );
  }

  deleteItemTag(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + '/staff/item-tag/' + id,
    );
  }

  queryItemTags(params: ShopItemTagQueryParams):
  Observable<QueryResult<ShopItemTag>> {
    return this.http.post<QueryResult<ShopItemTag>>(
        this.remitApiUrl + '/staff/item-tags',
        params,
    );
  }

  createShopBranch(data: Partial<RemitShopBranch>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/shop-branch',
        data,
    );
  }

  updateShopBranch(id: string, data: Partial<RemitShopBranch>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/shop-branch/' + id,
        data,
    );
  }
  getShopBranch(id: string): Observable<RemitShopBranch> {
    return this.http.get<{body: RemitShopBranch}>(
        this.remitApiUrl + '/staff/shop-branch/' + id,
    ).pipe(map((v)=> v.body));
  }

  queryShopBranch(params: ShopBranchQueryParams):
  Observable<QueryResult<RemitShopBranch>> {
    return this.http.post<QueryResult<RemitShopBranch>>(
        this.remitApiUrl + '/staff/shop-branches',
        params,
    );
  }
  deleteShopBranch(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + '/staff/shop-branch/' + id,
    );
  }

  refreshOrderView(): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/orders/populate/orders-view',
        null,
    );
  }

  orderStatus(): Observable<Record<string, string>> {
    return of({
      '100': 'Waiting',
      '200': 'Paid/Pending',
      '300': 'Processed',
      '400': 'Completed',
      '500': 'Expired',
      '600': 'Problem',
      '700': 'Cancelled',
      '701': 'Rejected',
      '702': 'Returned',
    });
  }

  remitPaymentMode(): Observable<{id: number; name: string}[]> {
    return of([
      {id: 0, name: 'kode minimarket'},
      {id: 1, name: 'cash'},
    ]);
  }

  paymentMethod(): Observable<{id: number; name: string}[]> {
    return of([
      {id: 0, name: 'CVS 超商店代嗎'},
      {id: 1, name: 'Cash 現金'},
      {id: 2, name: 'LINE Pay'},
      {id: 3, name: 'CC 刷卡'},
      {id: 4, name: 'COD 貨到付款'},
      {id: 5, name: 'Bank Transfer'},
      {id: 6, name: 'Uber Eats'},
    ]);
  }


  shippingProvider(): Observable<{id: number; name: string}[]> {
    return of([
      {id: 0, name: 'No Shipping'},
      {id: 1, name: '黑貓'},
      {id: 5, name: '7-11'},
      {id: 6, name: '全家'},
      {id: 7, name: 'HI-LIFE'},
      {id: 8, name: 'OK Mart'},
    ]);
  }

  removeItem(orderId: string, index: number): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${orderId}/remove-item/${index}`,
        {},
    );
  }

  addItem(
      orderId: string,
      items: Partial<RemitOrderItem>[],
  ): Observable<RemitApiResponse> {
    console.log('addItem', orderId, items);
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${orderId}/add-item`,
        {items: items},
    );
  }

  updateOrderExport(orderId: string): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${orderId}/export`,
        {},
    );
  }

  editOrderItem(orderId: string, itemIndex: number, updateItem: ShopOrderItemUpdate):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${orderId}/edit-item/${itemIndex}`,
        updateItem,
    );
  }
}
