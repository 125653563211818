import {Injectable} from '@angular/core';
import {Observable, forkJoin, from, map, of, switchMap} from 'rxjs';
import {AuthService, ChangePasswordRequest, User} from './auth.service';

import {Auth, EmailAuthProvider, GoogleAuthProvider, authState, reauthenticateWithCredential, signInWithEmailAndPassword, signInWithPopup, signOut, updatePassword} from '@angular/fire/auth';

@Injectable()
export class FireAuthService implements AuthService {
  constructor(private auth: Auth) {
  }


  login(email: string, password: string): Observable<string> {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
        map(()=> 'Success Login'),
    );
  }
  loginWithGoogle(): Observable<string> {
    const provider = new GoogleAuthProvider();
    return from(signInWithPopup(this.auth, provider)).pipe(
        map(()=> 'Success Login'),
    );
  }
  logout(): void {
    signOut(this.auth);
  }
  async getToken(): Promise<string | undefined> {
    const user = this.auth.currentUser;
    if ( !user) return undefined;
    return user.getIdToken();
  }
  authState(): Observable<User | null> {
    return authState(this.auth).pipe(
        switchMap((user)=>{
          return forkJoin([
            of(user),
            from(user?.getIdTokenResult()??Promise.resolve(null)),
          ]);
        }),
        map(([u, tokenResult])=>{
          if (!u) return null;
          return {
            uid: u.uid,
            email: u.email ?? '',
            name: u.displayName ?? '',
            emailVerified: u?.emailVerified ?? false,
            manager: tokenResult?.claims['manager'] as boolean ?? false,
            staff: tokenResult?.claims['staff'] as boolean ?? false,
            idpAgentCode: tokenResult?.claims['idpAgentCode'] as string ?? '',
            permissions: tokenResult?.claims['permissions'] as string[] ?? [],
          };
        }));
  }

  changePassword(req: ChangePasswordRequest): Observable<any> {
    const user = this.auth.currentUser;
    const email = user?.email;

    if (!email) {
      throw new Error('Account has no email');
    }
    if (!req.newPassword) {
      throw new Error('New password may not be empty');
    }
    if (req.newPassword != req.newPasswordConfirm) {
      throw new Error('New Password Confirmation not same');
    }

    const cred = EmailAuthProvider.credential(
        email, req.oldPassword,
    );

    return from(reauthenticateWithCredential(user, cred)).pipe(
        switchMap(()=>{
          return from(updatePassword(user, req.newPassword ));
        }),
    );
  }
}
