import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';

export interface Fee{
  name: string
  valueNTD: string
  isTaxed: 'Y' | 'N'
}


export interface FeesResponse{
  fees: Fee[]
}


@Injectable({
  providedIn: 'root',
})
export class OrderFeesService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }


  getLatestFees(): Observable<FeesResponse> {
    return this.http
        .get<FeesResponse>(this.remitApiUrl+`/staff/fee`);
  }

  updateFees(fees: Fee[]): Observable<RemitApiResponse> {
    return this.http
        .post<RemitApiResponse>(this.remitApiUrl+`/staff/fee`, {
          fees: fees,
        });
  }
}
