import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {
  AuthHttpInterceptor,
  AuthService,
  FireAuthService,
} from '@indosuara/auth';
import {QINVENTORY_BASE_URL, REMIT_BASE_URL} from '@indosuara/remit-api';
import {browserLocalPersistence, setPersistence} from 'firebase/auth';
import {ISMEMBER_BASE_URL} from 'ismember-api';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {appRoutes} from './app.routes';

export const DATEFMT = {
  parse: {
    dateInput: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD', 'YYMMDD'],
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {initialNavigation: 'enabledBlocking'}),

    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSidenavModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      auth.tenantId = environment.staffTenantId ?? 'employee-bml6n';
      setPersistence(auth, browserLocalPersistence);
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    {
      provide: AuthService,
      useClass: FireAuthService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: REMIT_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/remitz/api/v0',
    },
    {
      provide: QINVENTORY_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/qinventory/api/v0/staff',
    },
    {
      provide: ISMEMBER_BASE_URL,
      useValue: environment.indosuaraApiUrl,
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: DATEFMT},
  ],
})
export class AppModule {}
