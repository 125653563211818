

export const shopPurchaseOrderRoutes = [
  {
    path: '',
    loadComponent: () =>
      import('./shop-purchase-order.component').
          then((m) => m.ShopPurchaseOrderComponent),
  },
  {
    path: 'form',
    loadComponent: () =>
      // eslint-disable-next-line max-len
      import('./shop-pruchase-order-form/shop-purchase-order-form.component').
          then((m) => m.ShopPurchaseOrderFormComponent),
  },
  {
    path: 'suppliers',
    loadComponent: () =>
      import('../shop-suppliers/shop-suppliers.component').
          then((m) => m.ShopSuppliersComponent),
  },
  {
    path: ':id',
    loadComponent: () =>
      // eslint-disable-next-line max-len
      import('./shop-pruchase-order-form/shop-purchase-order-form.component').
          then((m) => m.ShopPurchaseOrderFormComponent),
  },
];
