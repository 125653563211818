import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map, switchMap} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {Comments, PaginationParams} from './remit-invoice.service';

export interface MemberQueryParams {
  pageSize?: number
  pageNumber?: number
  arcNumber?: string | null;
  phoneNumber?: string| null;
  name?: string| null;
  sortDirection?: number| null;
  sortBy?: string| null;
  memberIds?: string[] | null;
}

export interface RemitMemberCreateForm{
  name: string
  phoneNumber: string
  dateOfBirth: string
  arcExpiredDate: string
  arcNo: string
  arcFrontImage: string
  arcBackImage: string
  selfieImage: string
  userSignatureImage: string
}

export interface RemitMembers{
  body?: RemitMember[]
  totalData: number
}

export type RemitMember = RemitMemberBasicInfo & RemitMemberUpdatableInfo

export type RemitMemberBasicInfo = {
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  referralCode?: string
  referredBy?: string
} & RemitMemberUpdatableArray

export interface RemitMemberUpdatableArray{
  phoneNumbers: string[]
  attachments: string[]
}

export interface RemitMemberUpdatableInfo{
  name: string
  arcNo: string
  phoneNumber: string
  arcOld: string
  arcType: string
  arcExpiredDate: string
  arcIssueDate: string
  dateOfBirth: string
  sex: string
  address: string
  isBlacklist: string
  blacklistReason?: string
  limitTxnPerMonth: string
  limitNTDPerMonth: string
  limitNTDPerYear: string
  arcFrontImage: string
  arcBackImage: string
  selfieImage: string
  userSignatureImage: string
  arcBarcodeNumber: string;
  nationality: string;
  institution: string;
  passportNumber: string;
}

export interface RemitBenefCreateForm{
  bankCode: string
  accountNumber: string
  dateOfBirth: string
}


@Injectable({
  providedIn: 'root',
})
export class RemitMemberService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  queryMembers(params: MemberQueryParams,
  ): Observable<RemitMembers> {
    return this.http.post<RemitMembers>(
        this.remitApiUrl + '/staff/members',
        params,
    );
  }

  getMember(id: string): Observable<RemitMember> {
    return this.http.get<{body: RemitMember}>(
        this.remitApiUrl + '/staff/member/' + id,
    ).pipe(map((v)=> v.body));
  }
  updateMember(id: string, data: Partial<RemitMemberUpdatableInfo>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/member/' + id,
        data,
    );
  }

  detachMemberPhone(id: string):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/member/${id}/detach-phone-number`,
        null,
    );
  }
  pushAttachment(id:string, url: string):
  Observable<RemitApiResponse> {
    return this.getMember(id).pipe(
        switchMap((member)=>{
          const existing = member.attachments ?? [];
          return this.http.put<RemitApiResponse>(
              this.remitApiUrl+`/staff/member/${id}`,
              {
                attachments: [
                  ...existing,
                  url,
                ],
              },
          );
        }),
    );
  }
  removeAttachmentAt(id:string, position: number):
  Observable<RemitApiResponse> {
    return this.getMember(id).pipe(
        switchMap((member)=>{
          return this.http.put<RemitApiResponse>(
              this.remitApiUrl+`/staff/member/${id}`,
              {
                attachments: member.attachments?.filter(
                    (_, index) => index != position),
              },
          );
        }),
    );
  }


  createMemberComment(id: string, comment: string)
  : Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/member/${id}/comment`,
        {
          content: comment,
        },
    );
  }

  queryMemberComments(id: string, params: PaginationParams) :
  Observable<Comments> {
    return this.http.post<Comments>(
        this.remitApiUrl + `/staff/member/${id}/comments`,
        params,
    );
  }

  deleteMemberComment(memberId: string, commentId: string)
  : Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + `/staff/member/${memberId}/comment/${commentId}`,
    );
  }

  createMember(data: Partial<RemitMemberCreateForm>):
  Observable<RemitMemberCreateForm> {
    return this.http.post<RemitMemberCreateForm>(
        this.remitApiUrl + `/staff/member`,
        data,
    );
  }

  addBenef(userId: string, data: Partial<RemitBenefCreateForm>):
  Observable<RemitBenefCreateForm> {
    return this.http.post<RemitBenefCreateForm>(
        this.remitApiUrl + `/staff/beneficiary/${userId}`,
        data,
    );
  }
}
