import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';

export interface UpdateBeneficiaryRulesParams{
  reason: string
  limitTrxNumber: string
}

export type CreateBeneficiaryRulesParams = {
  bankCode: string
  accountNumber: string
} & UpdateBeneficiaryRulesParams

export interface BeneficiaryRules {
  body: BeneficiaryRule[]
  totalData: number;
}

export type BeneficiaryRule = CreateBeneficiaryRulesParams & {
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  version: string;
}

export interface BeneficiaryRulesQueryParams {
  pageNumber: number;
  pageSize: number;
  bankCode?: string | null;
  accountNumber?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class RemitBeneficiariesService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }


  createBenefRule(params: CreateBeneficiaryRulesParams):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/beneficiary-rule',
        params,
    );
  }

  updateBenefRule(id: string, params: Partial<UpdateBeneficiaryRulesParams>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/beneficiary-rule/${id}`,
        params,
    );
  }

  getBenefRule(id: BeneficiaryRule):
  Observable<BeneficiaryRule> {
    return this.http.get<{body: BeneficiaryRule}>(
        this.remitApiUrl + `/staff/beneficiary-rule/${id}`,
    ).pipe(map((v)=> v.body));
  }

  queryBenefRule(params: Partial<BeneficiaryRulesQueryParams>):
  Observable<BeneficiaryRules> {
    return this.http.post<BeneficiaryRules>(
        this.remitApiUrl + `/staff/beneficiary-rules`,
        params,
    );
  }
}
