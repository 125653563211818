import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, concatMap, map, of, scan} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {OrderQueryParams, RemitInvoice, RemitInvoices} from './remit-invoice.service';


export interface TransferOption{
  id: string
  name: string
}

export interface VostroBalanceInquiryResult{
  currency: string
  balance: string
}

export interface BalanceHistory{
  currency: string,
  bank: string,
  year: number,
  month: number,
  day: number,
  hour: number,
  avgBalance: number,
  minBalance: number,
  maxBalance: number,
  firstBalance: number,
  lastBalance: number,
  timestamp: string,
}

export interface BalanceHistories{
  body: BalanceHistory[]
  totalData: number,
}

@Injectable({
  providedIn: 'root',
})
export class ProcessTransferService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  queryOrders(params: OrderQueryParams): Observable<RemitInvoices> {
    return this.http.post<RemitInvoices>(
        this.remitApiUrl + '/staff/remit-invoices/ready',
        params,
    );
  }

  getBalance(providerId: string): Observable<VostroBalanceInquiryResult> {
    return this.http.get<VostroBalanceInquiryResult>(
        this.remitApiUrl + `/staff/vostro-balance/idr/${providerId}`,
    );
  }

  getBalanceHistory(params: {
    bank?: string,
    timestampStart?: string | null,
    timestampEnd?: string | null,
    pageNumber?: number | null,
    pageSize?: number | null,
  }) :Observable<BalanceHistories> {
    // /vostro-histories
    return this.http.post<BalanceHistories>(
        this.remitApiUrl + `/staff/vostro-histories`,
        params,
    );
  }


  transferOptions(): Observable<TransferOption[]> {
    return this.http.post<{body: TransferOption[]}>(
        this.remitApiUrl + '/staff/remit-invoice/transfer-options',
        {},
    ).pipe(map((v)=>v.body));
  }

  doProcessTransfer(
      id: string, transferMethod: string,
  ):Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/remit-invoice/' + id + '/transfer',
        {transferMethod},
    );
  }

  doManyTransfers(
      infos: RemitInvoice[], transferMethod: string,
  ): Observable<
  {
    progress: string,
    totalTransferred: string,
    response: RemitApiResponse[]
  }> {
    const totalAmountToTransfer = infos.
        reduce((total, info) => total + parseFloat(info.foreignAmount), 0);

    return of(...infos).pipe(
        concatMap((info) =>
          this.doProcessTransfer(info.transactionId, transferMethod).pipe(
              map((response) =>
                ({foreignAmount: parseFloat(info.foreignAmount), response})),
          ),
        ),
        scan((acc, value) => {
          return {
            count: acc.count + 1,
            totalTransferred: acc.totalTransferred + value.foreignAmount,
            responses: [...acc.responses, value.response],
          };
        }, {
          count: 0,
          totalTransferred: 0,
          responses: [] as RemitApiResponse[],
        }),
        map((acc) => ({
          // eslint-disable-next-line max-len
          progress: `${acc.count} out of ${infos.length} processed, ${acc.totalTransferred} out of ${totalAmountToTransfer} uploaded`,
          response: acc.responses,
          totalTransferred: acc.totalTransferred.toString(),
        })),
    );
  }
}
