import {Route} from '@angular/router';
import {shopPurchaseOrderRoutes} from './shop-purchase-order/shop-purchase-order.routes';

export const shopsRoutes: Route[] = [
  {
    path: 'shop-items',
    loadChildren: () =>
      import('./remit-shop-items/remit-shop-items.module').
          then((m) => m.RemitShopItemsModule),
  },
  {
    path: 'shop-orders',
    loadChildren: () =>
      import('./remit-shop-orders/remit-shop-orders.module').
          then((m) => m.RemitShopOrdersModule),
  },
  {
    path: 'shop-branches',
    loadChildren: () =>
      import('./shop-branches/shop-branches-routing.module').
          then((c) => c.ShopBranchesRoutingModule),
  },
  {
    path: 'shop-feature',
    loadComponent: () =>
      import('./shop-feature/shop-feature.component').
          then((m) => m.ShopFeatureComponent),
  },
  {
    path: 'shop-item-tag-list',
    loadComponent: () =>
      import('./shop-item-tag-list/shop-item-tag-list.component').
          then((m) => m.ShopItemTagListComponent),
  },
  {
    path: 'shop-order-summary',
    loadComponent: () =>
      import('./shop-order-summary/shop-order-summary.component').
          then((m) => m.ShopOrderSummaryComponent),
  },
  {
    path: 'shop-order-item-summary',
    loadComponent: () =>
      import('./shop-order-item-summary/shop-order-item-summary.component').
          then((m) => m.ShopOrderItemSummaryComponent),
  },
  {
    path: 'shop-purchase-order',
    children: shopPurchaseOrderRoutes,
  },

];


