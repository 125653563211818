import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';

@Injectable({
  providedIn: 'root',
})
export class RemitPublicImageUploaderService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  uploadPublicImage(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<RemitApiResponse & {url:string}>(
        `${this.remitApiUrl}/staff/contents/images`,
        formData,
    ).pipe(map((v)=>v.url));
  }
}
