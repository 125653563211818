import {format, parseISO} from 'date-fns';

export function isExport(): string {
  return 'is-export';
}


export function rfc3339ToYYYYMMDDTaiwanTime(rfc3339String: string): string {
  const date = new Date(rfc3339String);

  const taiwanTime = new Date(date.getTime() + 8 * 60 * 60 * 1000);

  const year = taiwanTime.getUTCFullYear();
  const month = taiwanTime.
      getUTCMonth() + 1; // Months are zero-indexed, so add 1
  const day = taiwanTime.getUTCDate();

  // eslint-disable-next-line max-len
  const formattedDate = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
  return formattedDate;
}

export function convertPhoneNumberToTaiwanLocalFormat(
    internationalNumber: string): string {
  if (internationalNumber.startsWith('+886')) {
    return '0' + internationalNumber.substring(4);
  }
  return '';
}


export function convertRFC3339ToYYYYMMDDTaiwanSlashed(
    rfc3339Date: string): string {
  if (!rfc3339Date) {
    return '';
  }
  const date = parseISO(rfc3339Date);
  return format(date, 'yyyy/MM/dd');
}


export function generateFileDateName(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}${month}${day}`;
  return formattedDate;
}

export function exportArrayToXls(
    data: (string|number)[][],
    sheetName='Sheet 1',
    filename = 'export.xls') {
  return import('xlsx').then((XLSX) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(
        wb,
        filename,
        {
          bookType: 'biff8',
        },
    );
  });
}

export function exportArrayToCSV(
    data: (string|number)[][], filename = 'export.csv') {
  // Step 1: Convert array of arrays to CSV format
  const csvContent = data
      .map((row) => row.map((value) => `"${value}"`).join(','))
      .join('\n');

  // Step 2: Create a Blob from the CSV string
  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});

  // Step 3: Create a downloadable link for the Blob and trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}


export function arrayOfObjectsToArrayOfArrays(
    arrayOfObjects: object[]): (string|number)[][] {
  if (!arrayOfObjects) return [];
  if (!arrayOfObjects.length) return [];
  const headerRow = Object.keys(arrayOfObjects[0]);
  const dataRows = arrayOfObjects.map((obj) => Object.values(obj));
  return [headerRow, ...dataRows];
}
