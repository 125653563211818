import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {OrderQueryParams} from './remit-invoice.service';


export interface MemberTransactionSummary{
  memberName: string
  arcNumber: string
  phoneNumber: string
  transactionCount: number
  totalNTDSent: number
}


export interface MemberTransactionSummaries{
  body: MemberTransactionSummary[]
  totalData: number
}

export interface QueryMemberTrxSummaryParams{
  pageSize?: number
  pageNumber?: number
  paidAtStart?: string | null
  paidAtEnd?: string | null
}


export interface OutflowBankDetails{
  bank: string
  totalNTD: number
  totalForeignAmount: number
  foreignCurrency: string
  count: number
}
export interface OutflowTransaction{
  transactionDate: string
  banks: OutflowBankDetails[]
}

export interface OutflowTransactions{
  body: OutflowTransaction[]
  totalData: number
}


export interface RemitInflowSummary{
  transactionDate: string
  transactionCount: number
  totalPaid: number
  totalServiceCharges: number
  totalTransferAmount: number
  totalPromos: number
}

export interface RemitInflowSummaries{
  body?: RemitInflowSummary[]
  totalData: number
}


@Injectable({
  providedIn: 'root',
})
export class RemitSummaryService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }


  queryMemberTransactionSummary(params: QueryMemberTrxSummaryParams,
  ): Observable<MemberTransactionSummaries> {
    return this.http.post<MemberTransactionSummaries>(
        this.remitApiUrl + '/staff/member-transaction-summary',
        params,
    );
  }
  outflowSummary(params: OrderQueryParams,
  ): Observable<OutflowTransactions> {
    return this.http.post<OutflowTransactions>(
        this.remitApiUrl + '/staff/transfer-summary',
        params,
    );
  }
  inflowSummary(params: OrderQueryParams,
  ): Observable<RemitInflowSummaries> {
    return this.http.post<RemitInflowSummaries>(
        this.remitApiUrl + '/staff/paid-invoice-summary',
        params,
    );
  }
}
