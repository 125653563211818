<ng-container *ngIf="stock$ | async as stock; else loading">
  <div class="stock-container">
    <div class="light-border">
      {{stock.quantity}} left.
    </div>
    <span *ngIf="stock.errorMessage" class="err-msg">{{stock.errorMessage}}</span>
  </div>
</ng-container>

<ng-template #loading>
  <div class="stock-container">
    <div class="light-border">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  </div>
</ng-template>