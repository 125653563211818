import {InjectionToken} from '@angular/core';

export const REMIT_BASE_URL = new InjectionToken<string>(
    'Base Url for Remittance Api', {
      providedIn: 'root',
      factory: () => 'localhost',
    },
);
export const QINVENTORY_BASE_URL = new InjectionToken<string>(
    'Base Url for Qinventory Api', {
      providedIn: 'root',
      factory: () => 'localhost',
    },
);

// https://api.staging.indosuara.com/arc-automation/validate-arc


export const REMIT_AUTO_ARC_BASE_URL = new InjectionToken<string>(
    'Base Url for Remittance auto arc check', {
      providedIn: 'root',
      factory: () => 'localhost',
    },
);


export const DEFAULT_ORDER_SHOP_CODE_FILTER = new InjectionToken<string>(
    'default shop code when filtering', {
      providedIn: 'root',
      factory: () => '',
    },
);
