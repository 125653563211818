import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {ISMEMBER_BASE_URL} from '../config';

export interface MemberQueryParams {
  pageNumber?: number | null
  pageSize?: number | null
  sortBy?: string | null
  sortDirection?: number | null
  name?: string | null
  passportNumber?: string | null,
  phoneNumber?: string | null,
  arcNumber?: string | null,
  address?:string|null,
  groupId?: string|null
}

export interface MembersProfile{
  body?: MemberProfile[]
  totalData: number
}

export interface MemberProfile{
  id: number
  uid: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  name: string
  phoneNumber: string
  arcNumber: string
  bp2miId: string
  hasBp2mi: boolean
  passportNumber: string
  address: string
  groupId?: string
}

export interface MemberProfileResponse {
  message?: string;
  body?:MemberProfile;
  error?:string;
}

export interface CustomerGroup {
  id: string
  name: string
  customerIds: string[]
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface CustomerGroupQueryParams {
  pageNumber?: string
  pageSize?:string
  customerId: string
  customerIds: string[]
}


export interface CustomerGroupResponse {
  message?: string;
  body?:CustomerGroup[];
  error?:string;
}

@Injectable({
  providedIn: 'root',
})
export class MemberProfileService {
  constructor(
    private http: HttpClient,
    @Inject(ISMEMBER_BASE_URL) private indoposApiUrl: string,
  ) {}
  queryMembers(params?: Partial<MemberQueryParams>):Observable<MembersProfile> {
    return this.http.post<MembersProfile>(
        this.indoposApiUrl + '/ismember/api/v0/staff/members',
        params,
    );
  }
  getMemberByPhoneNumber(phone: string): Observable<MemberProfile | null> {
    return this.queryMembers({
      pageNumber: 0,
      pageSize: 1,
      phoneNumber: phone,
    }).pipe(
        map((res)=> res.body?.[0] ?? null),
    );
  }

  getMemberByUid(uid: string): Observable<MemberProfile | null> {
    return this.http.get<MemberProfileResponse>(this.indoposApiUrl+
        `/ismember/api/v0/staff/member/${uid}`).pipe(
        map((res)=>res.body ?? null),
    );
  }
  addCustomerToCustomerGroup(id:string, uid: string): Observable<MemberProfileResponse> {
    return this.http.post<MemberProfileResponse>(this.indoposApiUrl+
      `/ismember/api/v0/staff/member/${uid}/group/${id}/add`, {});
  }
  queryCustomerGroups():Observable<CustomerGroupResponse> {
    return this.http.post<CustomerGroupResponse>(this.indoposApiUrl+
     `/ismember/api/v0/staff/groups`, {
      pageNumber: 0,
      pageSize: -1,
    });
  }
  newCustomerGroup(name: string):Observable<MemberProfileResponse> {
    return this.http.post<MemberProfileResponse>(this.indoposApiUrl+
      '/ismember/api/v0/staff/group', {
      name: name,
    });
  }

  upsertMember(data: Partial<MemberProfile>): Observable<MemberProfileResponse> {
    return this.http.post<MemberProfileResponse>(this.indoposApiUrl+
        '/ismember/api/v0/staff/member', data);
  }
}
