import {Injectable} from '@angular/core';
import {MsjhFontTtfResolver} from '@indosuara/msjh-font';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import {RemitInvoice} from './remit-invoice.service';


@Injectable({
  providedIn: 'root',
})
export class RemitInvoicePdfService {
  constructor(
    private fontResolver: MsjhFontTtfResolver,
  ) { }

  private base64Cache: { [url: string]: string } = {};

  exportPdf(invoice: RemitInvoice) {
    return this.renderAndExportInvoice(invoice);
  }

  private async renderAndExportInvoice(ri:RemitInvoice) {
    const doc = new jsPDF('p', 'mm', 'a5');
    const pageWidth = doc.internal.pageSize.getWidth();

    const font = await this.fontResolver.resolve();


    const indosuaraLogo = await this.convertToBase64('assets/logo_indosuara_hd.png');
    doc.addFileToVFS('msjh-normal.ttf', font);
    doc.addFont('msjh-normal.ttf', 'msjh', 'normal');


    const fee = ri.fees.reduce((agg, cur)=>{
      return agg + Number(cur.valueNTD);
    }, 0) - ri.promos.reduce((agg, cur)=>{
      return agg + Number(cur.amountNTD);
    }, 0);

    const opt: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'TWD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };

    const jumlahPengiriman = new Intl.NumberFormat(
        'zh-TW', opt).format(Number(ri.amountNTD));

    const jumlahFee = new Intl.NumberFormat(
        'zh-TW', opt).format(Number(fee));
    const jumlahTotal = new Intl.NumberFormat(
        'zh-TW', opt).format(Number(ri.totalPaymentNTD));

    const table = [
      ['委託人姓名', 'Nama Pengirim', ri.sender?.name ?? ''],
      ['電話 / 手機', 'Nomor Telepon/HP', ri.sender?.phoneNumber.replace('+886', '0') ?? ''],
      ['受款人姓名', 'Nama Penerima', ri.beneficiaryName ?? ''],
      ['受款人 電話 / 手機', 'Nomor Telepon/HP Penerima', ''],
      ['銀行名稱', 'Bank', ri.bankName ?? ''],
      ['帳號', 'Nomor Account', ri.beneficiaryAccountNumber ?? ''],
      ['匯出金額', 'Jumlah Pengiriman', jumlahPengiriman ?? ''],
      ['服務費', 'Biaya Pengiriman', jumlahFee|| ''],
      ['總金額', 'Jumlah Total', jumlahTotal|| ''],
    ];


    const padding = 13.5;
    const rowSize = 6;
    const startsFrom = padding;
    let cursor = startsFrom;

    doc.setFontSize(12);
    doc.setFont('msjh');


    // doc.addImage(indosuaraLogo, 'PNG', padding, cursor, 33.3, 8.7);
    doc.text('Resi Pengiriman Uang', pageWidth/2, cursor + 4.35);
    doc.text('No. ' + ri.transactionId, pageWidth/2, cursor + 4.35 + rowSize);


    cursor += cursor + rowSize * 2;

    const colonSpacing = 48;
    const col1Width = 120;
    const tableRowSize = rowSize * 2;

    for (let index = 0; index < table.length; index++) {
      const labelValue = table[index];
      createColumn(
          labelValue,
          padding, cursor, colonSpacing, col1Width);
      cursor += tableRowSize;
    }
    cursor += rowSize * 2;

    doc.text('服務人員簽章：', padding, cursor);
    doc.text('Tanda Tangan Petugas:', padding, cursor + 4);

    doc.text('委託人簽章：', padding + pageWidth/2, cursor);
    doc.text('Tanda Tangan Pengirim:', padding + pageWidth/2, cursor + 4);

    cursor += rowSize*6;
    doc.text('Date(YY/MM/DD): ' + moment(ri.createdAt).
        format('YY/MM/DD'), padding + pageWidth/2, cursor);


    doc.save('invoice'+(new Date()).toISOString()+'.pdf');


    function drawWaterMark() {
      doc.saveGraphicsState();

      doc.setGState(doc.GState({opacity: 0.1}));


      const startx = 0;
      const starty = 0;

      const logowidth = pageWidth / 2 / 3;
      const logoheight = logowidth * 0.26;

      const distancex = 20;
      const distancey = 20;
      for (let i = 0; i < 16; i++) {
        for (let j = 0; j < 16; j++) {
          doc.addImage(indosuaraLogo,
              'PNG', startx + (distancex * j),
              starty+ (distancey * i),
              logowidth,
              logoheight, '', 'FAST', 45);
        }
      }


      doc.restoreGraphicsState();
    }

    function createColumn(
        labelValue: string[],
        padding: number,
        tablePosX: number,
        colonSpacing: number,
        width: number,
    ) {
      doc.setFontSize(10);
      doc.rect(padding, tablePosX, width, 12);
      doc.text(labelValue[0], padding + 2, tablePosX + 5);
      doc.setFontSize(8);
      doc.text(labelValue[1], padding + 2, tablePosX + 9);
      doc.setFontSize(10);
      doc.text(':', padding + colonSpacing, tablePosX + 5);
      doc.text(':', padding + colonSpacing, tablePosX + 9);
      // if value is only a single value
      if (labelValue.length == 3) {
        doc.text(labelValue[2], padding + colonSpacing + 4, tablePosX + 7);
      }
      // if have english and chinese value
      if (labelValue.length == 4) {
        doc.text(labelValue[2], padding + colonSpacing + 4, tablePosX + 5);
        doc.setFontSize(8);
        doc.text(labelValue[3], padding + colonSpacing + 4, tablePosX + 9);
        doc.setFontSize(10);
      }
    }
  }


  private async convertToBase64(url: string): Promise<string> {
    if (this.base64Cache[url]) {
      return this.base64Cache[url];
    }


    const res = await fetch(url);
    const buffer = await res.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0, len = bytes.byteLength; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = window.btoa(binary);

    this.base64Cache[url] = base64;

    return base64;
  }
}
