<ng-container *ngIf="item$ | async as item">
  <div class="row">
    <div class="image-container">
      <img [src]="item.image">
    </div>
    <div>
      {{item.name}}
    </div>
    <div>
      {{item.price | number}} NTD
    </div>
  </div>
</ng-container>