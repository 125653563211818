import {Injectable} from '@angular/core';
import * as saveAs from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import * as XLSX from 'xlsx';
import {RemitShopService} from './remit-shop.service';

export interface OrderSummaryExportFormat {
  sku?: string;
  itemId?: string;
  name?: string;
  capitalPrice?: number;
  price?: number;
  totalQuantity?: number;
  totalGross?: number;
  totalNet?: number;
  notes?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShopOrderSummaryExportExcelService {
  constructor(private shopService: RemitShopService) {}

  private _getData(filters: any): Observable<any> {
    const value = _.clone(filters);
    value.createdAtEnd = moment(value.createdAtEnd).endOf('day').toISOString();

    return this.shopService.queryOrderItemsSummary({
      ...value,
      pageSize: 1000,
    });
  }

  private _generateFileName(): string {
    return `order-summary-${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  exportToExcel(filters: any) {
    this._getData(filters).subscribe({
      next: (res) => {
        if (res) {
          this._generateExcel(res, filters);
        } else {
          console.error('No data found');
        }
      },
      error: (err) => {
        console.error('Error fetching data:', err);
      },
    });
  }

  private _generateExcel(res: any, filters: any) {
    const data = res.body;
    const totalGross = res.totalGross;
    const totalNet = res.totalNet;

    const headerAoA = [
      ['Date Range',
        // eslint-disable-next-line max-len
        `${moment(filters.createdAtStart).format('YYYY/MM/DD')} - ${moment(filters.createdAtEnd).format('YYYY/MM/DD')}`],
    ];

    const ws = XLSX.utils.aoa_to_sheet(headerAoA);

    data.push({
      totalGross: totalGross,
      totalNet: totalNet,
    });

    XLSX.utils.sheet_add_json(ws, data, {
      origin: {r: headerAoA.length, c: 0},
      skipHeader: false,
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Order Summary');

    const excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const dataBlob: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(dataBlob, this._generateFileName());
  }
}
