import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {map, take} from 'rxjs';
import {AuthService} from './auth.service';

export const canActivateLoggedIn: CanActivateFn = (
    _route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.authState().pipe(
      map((user) => !!user?.staff),
      map((loggedIn)=>{
        if (!loggedIn) {
          router.navigate(['/login'], {
            queryParams: {redirectUrl: state.url},
          });
        }
        return loggedIn;
      }),
      take(1),
  );
};

export const canActivateLoggedInChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
) => canActivateLoggedIn(route, state);


export const canActivateSuperAdmin: CanActivateFn = (
    _route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.authState().pipe(
      map((user) => !!user?.manager),
      map((isManager)=>{
        if (!isManager) {
          router.navigate(['/login'], {
            queryParams: {redirectUrl: state.url},
          });
        }
        return isManager;
      }),
      take(1),
  );
};


export function canActivateHasPermissionFn(permission: string):CanActivateFn {
  return (
      _route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ) => {
    const authService = inject(AuthService);
    const router = inject(Router);


    return authService.authState().pipe(
        map((user)=>{
          const hasPermission = user?.permissions.includes(permission) ?? false;
          if (!hasPermission) {
            router.navigate(['/404'], {
              queryParams: {redirectUrl: state.url},
            });
          }
          return hasPermission;
        }),
        take(1),
    );
  };
}


export function canActivateHasPermission(permission: string):
CanActivateChildFn {
  const canActivateChildFunction = (
      route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ) => canActivateHasPermissionFn(permission)(route, state);

  canActivateChildFunction.requiredPermission = permission;
  return canActivateChildFunction;
}
